import { SharedService } from 'src/global';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class IntegrationService {
    constructor(private http: HttpClient, public sharedService: SharedService) { }

    API_URL = this.sharedService.getGlobalVar();


    getFormats(val: string, userProfileId: number) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(this.API_URL + 'router/sentdata/' + userProfileId + '/?search=' + val, { headers: headers });

    }

    getNames(): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(this.API_URL + 'router/sentdata/', { headers: headers });
    }

    getFormatList() {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(this.API_URL + 'router/sentdata/', { headers: headers });
    }
    searchFormat(val: string, userProfileId: number) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(this.API_URL + 'router/sentdata/' + userProfileId + '/?search=' + val, { headers: headers })

    }
    getFormatDetails(format: any) {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.get(this.API_URL + 'sentdataformat/' + format, { headers: headers });
    }


    getFormatListtable(userProfileId) {
        const body = { "id": userProfileId }
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        return this.http.post(this.API_URL + 'router/sentdata/', body, { headers: headers });
    }

    addintegration(data: any) {
        const headers = new HttpHeaders();

        return this.http.post(this.API_URL + "router/sentdata/", data, { headers: headers })
    }

    updateIntegration(id: number, data: any) {
        const headers = new HttpHeaders();
        return this.http.put(this.API_URL + `router/sentdata/${id}/`, data, { headers: headers })
    }




    integrationdata() {
        const headers = new HttpHeaders();
        return this.http.get(this.API_URL + 'get-integrations/', { headers: headers })
    }

    getIntegrationById(id: number) {
        const headers = new HttpHeaders();
        return this.http.get(this.API_URL + `router/sentdata/${id}/`, { headers: headers })

    }

    deleteIntegration(id: number) {
        const headers = new HttpHeaders();
        return this.http.delete(this.API_URL + `router/sentdata/${id}/`, { headers: headers })

    }

}
