import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { WhatsappService } from '../whatsapp-services';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

declare var $: any;
@Component({
  selector: 'app-whatsapp-templates',
  templateUrl: './whatsapp-templates.component.html',
  styleUrls: ['./whatsapp-templates.component.css']
})
export class WhatsappTemplatesComponent implements OnInit {

  @Output() userEmitter: any = new EventEmitter();
  userInitial: string | null = null;
  userId: string = "";
  dataTable: any;
  userData: any;
  templateList: any[] = [];
  userClearTrigger = false;
  userReadOnly: boolean = false;
  userRequired: boolean = false;
  messageTypeList: any[] = [];
  showModal: boolean = false;
  validationMessages: any[] = [];
  
  constructor(
    private whatsappService: WhatsappService,
    private loginService: LoginService
  ) { }


  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);
    this.intitializerUserSettings();
    this.getMessageTypes();
    
  }

  ngAfterViewInit(): void {
    this.initializeDatatable();
    this.updateDataTable();   
  
  }

  ngOnDestroy(): void {
    this.dataTable.destroy();
  }

  getMessageTypes() {
    this.whatsappService.getMessageTypeList().subscribe((res: any) => {
      if (res.status === "1") {
        this.messageTypeList = res.data
      }
    })
  }


  intitializerUserSettings() {

    if (this.userData.usergroup != "superadmin") {
      this.userReadOnly = true;
    }
  }

  initializeDatatable() {
    this.dataTable = $('#templatesTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'sl_no', title: 'Sl No' },
        { data: 'username', title: 'Whatsapp User' },
        { data: 'template_name', title: 'Template Name' },
        { data: 'status', title: 'Status' },        
        { data: 'message_type', title: 'Message Type' },
        { data: 'priority_value', title: 'Priority'},
        { data: 'one_time', title: "One Time Template" },
        { data: 'is_deleted', title: "Deleted"}
      ]
    });
  }

  updateDataTable() {

    if (this.userData.usergroup == 'superadmin') {
      const qParams = [
        { key: "scope", value: "superadmin" }
      ]
      this.whatsappService.getTemplatesList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.templateList = res.data.map((item, index) => {
              let options = `<option value=""${item.message_type ? "" : " selected"}>Select a type</option>`;
              let priorityOptions = ''
              for (let i = 0; i < 100; i++){
                priorityOptions += `<option value="${i}" ${item.priority == i? "selected": ""}>${i}</option>`;
              }

              options += this.messageTypeList.map((type) => {
                return `<option value="${type.id}" ${item.message_type == type.id ? "selected" : ""}>${type.name}</option>`;
              }).join('');

              return {
                ...item,
                sl_no: index + 1,
                one_time: `<input id="one_time_template" type="checkbox" ${item.one_time_template ? "checked" : ""}>`,
                message_type: `<select id="message_type" name="message_type" class="form-control">${options}</select>`,
                priority_value: `<select id= "priority", name= "priority" class= "form-control">${priorityOptions}</select>`,
                status: item.is_valid ? `<i class="fa fa-check" style="color: green;"></i>` : `<button id="invalid_btn" type="button"><i class="fa fa-info-circle" style="color:red;"></i></button>`,
                is_deleted: `<input type="checkbox" ${item.deleted? "checked": ""} disabled/>`
              };
            });

            this.dataTable.clear();
            this.dataTable.rows.add(this.templateList);
            this.dataTable.draw();
          }
        }
      )

      $('#templatesTable tbody').off('change', '#message_type');
      $('#templatesTable tbody').off('change', '#priority');
      $('#templatesTable tbody').off('change', '#one_time_template');
      $('#templatesTable tbody').off('click', '#invalid_btn');

      $('#templatesTable tbody').on('change', '#message_type', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onChangeMessageType(row.id, event);
        }
      });

      $('#templatesTable tbody').on('change', '#priority', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onPriorityChange(row.id, event);
        }
      });

      $('#templatesTable tbody').on('change', '#one_time_template', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onCheckOneTimeTemplate(row.id, event);
        }
      });

      $('#templatesTable tbody').on('click', '#invalid_btn', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onViewInvalid(row);
        }
      });

    } else {
      const qParams = [
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: this.userData.id }
      ]
      this.whatsappService.getTemplatesList(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.templateList = res.data.map((item, index) => {
              let options = `<option value=""${item.message_type ? "" : " selected"}>Select a type</option>`;
              let priorityOptions = ''
              for (let i = 0; i < 100; i++) {
                priorityOptions += `<option value="${i}" ${item.priority == i ? "selected" : ""}>${i}</option>`;
              }

              options += this.messageTypeList.map((type) => {
                return `<option value="${type.id}" ${item.message_type == type.id ? "selected" : ""}>${type.name}</option>`;
              }).join('');

              return {
                ...item,
                sl_no: index + 1,
                one_time: `<input id="one_time_template" type="checkbox" ${item.one_time_template ? "checked" : ""}>`,
                message_type: `<select id="message_type" name="message_type" class="form-control">${options}</select>`,
                priority_value: `<select id= "priority", name= "priority" class= "form-control">${priorityOptions}</select>`,
                status: item.is_valid ? `<i class="fa fa-check" style="color: green;"></i>` : `<button id="invalid_btn" type="button"><i class="fa fa-info-circle" style="color:red;"></i></button>`,
                is_deleted: `<input type="checkbox" ${item.deleted ? "checked" : ""} disabled/>`
              }
            });

            this.dataTable.clear();
            this.dataTable.rows.add(this.templateList);
            this.dataTable.draw();
          }
        }
      )

      $('#templatesTable tbody').off('change', '#message_type');
      $('#templatesTable tbody').off('change', '#priority');
      $('#templatesTable tbody').off('change', '#one_time_template');
      $('#templatesTable tbody').off('click', '#invalid_btn');

      $('#templatesTable tbody').on('change', '#message_type', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onChangeMessageType(row.id, event);
        }
      });

      $('#templatesTable tbody').on('change', '#priority', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onPriorityChange(row.id, event);
        }
      });

      $('#templatesTable tbody').on('change', '#one_time_template', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onCheckOneTimeTemplate(row.id, event);
        }
      });

      $('#templatesTable tbody').on('click', '#invalid_btn', (event: any) => {

        const row = this.dataTable.row($(event.target).closest('tr')).data();
        if (row) {
          this.onViewInvalid(row);
        }
      });
    }
  }


  onChangeMessageType(id, event) {
    const data = {
      id: id,
      message_type: event.target.value
    }
    this.whatsappService.updateTemplate(data).subscribe((res: any) => {
      if (res.status === "1") {
        Swal.fire("Success", "Message type updated successfully.", "success")
      } else {
        Swal.fire("Something went wrong!", "Message type updation failed.", "error")
      }
    })
  }

  onPriorityChange(id, event) {
    const data = {
      id: id,
      priority: event.target.value
    }
    this.whatsappService.updateTemplate(data).subscribe((res: any) => {
      if (res.status === "1") {
        Swal.fire("Success", "Priority updated successfully.", "success")
      } else {
        Swal.fire("Something went wrong!", "Failed to update priority", "error")
      }
    })
    
  }

  onCheckOneTimeTemplate(id, event) {
    const data = {
      id:id,
      one_time_template: event.target.checked
    }
    this.whatsappService.updateTemplate(data).subscribe((res: any) => {
      if (res.status === "1") {
        Swal.fire("Success", "Message type updated successfully.", "success")
      } else {
        Swal.fire("Something went wrong!", "Message type updation failed.", "error")
      }
    })
  }

  onViewInvalid(row) {
    this.validationMessages = row.validation_status
    this.showModal = true;
  }

  onTemplateSync() {

    if (this.userId) {
      const data = {
        user_id: this.userId
      }

      this.whatsappService.fecthTemplates(data).subscribe((res: any) => {
        if (res.status === "1") {
          this.updateDataTable();
          Swal.fire("Success", "Templates fetched successfully.", "success");
        } else {
          Swal.fire("Something went wrong!", "Failed to fetch templates.", "error")
        }
      })
    } else {
      this.userRequired = true;     
    }

  }

  userEvent(userIds) {    
    this.userId = userIds.whatsappUserId;    
  }

  closeModal() {
    this.showModal = false;
  }
}




