import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { SharedService } from "./../../global";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient, public sharedService: SharedService) {}

  API_URL = this.sharedService.getGlobalVar();

  getInboundReport(
    userProfileId: number,
    fromDate,
    toDate,
    search,
    callStatus,
    current_page,
    download,
    usertype,
    tag_filter
  ) {
    const headers = new HttpHeaders();
    const body = {
      userProfileId: userProfileId,
      toDate: toDate,
      fromDate: fromDate,
      search: search,
      callStatus: callStatus,
      download: download,
      userType: usertype,
      tag_filter: tag_filter,
    };
    headers.append("Content-Type", "application/json");

    if (download == 'voice_download_yes') {
      return this.http.post(this.API_URL + 'InboundReport/?page=' + current_page, body, {
        responseType: "blob",
        reportProgress: true,
        observe: "events",
        headers: new HttpHeaders(
          {'content-Type':'application/json'},
        )
      })
    }
    else{
      headers.append('Content-Type','application/json');
      return this.http.post(this.API_URL + 'InboundReport/?page=' + current_page, body, { headers: headers });
    }
  }

  getdailpadReport(userProfileId: number, fromDate, toDate, search, callStatus, current_page, download, userType, tag_filter) {
    const headers = new HttpHeaders();
    const body = { "userProfileId": userProfileId, "toDate": toDate, "fromDate": fromDate, "search": search, "callStatus": callStatus, "download": download, "userType": userType, "no_callback_data": true, "tag_filter": tag_filter }
    if (download == 'voice_download_yes') {

      return this.http.post(this.API_URL + 'dailpadreport/?page=' + current_page, body, {

        responseType: "blob",
        reportProgress: true,
        observe: "events",
        headers: new HttpHeaders(
          { 'Content-Type': 'application/json' },)
      })

    }
    else {
      headers.append('Content-Type', 'application/json');
      return this.http.post(this.API_URL + 'dailpadreport/?page=' + current_page, body, { headers: headers });
    }

  }



  getDialerReport(
    userProfileId: number,
    fromDate,
    toDate,
    agent,
    project,
    feedback,
    current_page,
    val,
    download,
    search_value_text,
    userType,
    tag_filter
  ) {
    const headers = new HttpHeaders();
    const body = {"fromDate":fromDate,"toDate":toDate,"userProfileId":userProfileId,"agent":agent,"project":project,"feedback":feedback,"val":val, "download":download, "search_value":search_value_text,"userType":userType, "tag_filter":tag_filter}
    if (download=='voice_download_yes'){

      return this.http.post(this.API_URL+'DialerReport/?page='+current_page,body , {

        responseType: "blob",
        reportProgress: true,
        observe: "events",
        headers: new HttpHeaders(
          { 'Content-Type': 'application/json' },)
      }) 

    }


    else{
      headers.append('Content-Type','application/json');
      return this.http.post(this.API_URL+'DialerReport/?page='+current_page, body , {headers: headers})
    }
  }  


  getcallbackreport(userProfileId: number, fromDate, toDate, search, callStatus, current_page, download, userType, tag_filter) {
    const headers = new HttpHeaders();
    const body = { "userProfileId": userProfileId, "toDate": toDate, "fromDate": fromDate, "search": search, "callStatus": callStatus, "download": download, "userType": userType, "tag_filter": tag_filter }
    if (download == 'voice_download_yes') {
      return this.http.post(this.API_URL + 'CallbackReport/?page=' + current_page, body, {
        responseType: "blob",
        reportProgress: true,
        observe:"events",
        headers: new HttpHeaders(
          { 'content-Type': 'application/json' },
        )
      })
    }
    else {
      headers.append('Content-Type', 'application/json');
      return this.http.post(this.API_URL + 'CallbackReport/?page=' + current_page, body, { headers: headers });
    }
  }


  getVirtualsipReport(userProfileId: number, fromDate, toDate, search, callStatus, callDirection, current_page, download, usertype, tag_filter) {
    const headers = new HttpHeaders();
    const body = { "userProfileId": userProfileId, "toDate": toDate, "fromDate": fromDate, "search": search, "callStatus": callStatus, "callDirection": callDirection, "download": download, "userType": usertype, "tag_filter": tag_filter }
    headers.append('Content-Type', 'application/json');

    if (download == 'voice_download_yes') {
      return this.http.post(this.API_URL + 'VirtualsipReport/?page=' + current_page, body, {
        responseType: "blob",
        reportProgress: true,
        observe: "events",
        headers: new HttpHeaders(
          { 'Content-Type': 'application/json' },
        )

      })
    }

    else{
      headers.append('Content-Type','application/json');
      return this.http.post(this.API_URL+'VirtualsipReport/?page='+current_page,body, {headers:headers});
    }
  }

  getcallrecord(url, date, id = null, file_type = "") {
    const headers = new HttpHeaders();
    const body = { url: url, date: date, file_type: file_type, id: id };
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "callrecordfile/", body, {
      headers: headers,
    });
  }

  searchagentList(val: string, userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "UseragentList/" + userProfileId + "/?search=" + val,
      { headers: headers }
    );
  }

  getagentList(userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "UseragentList/" + userProfileId + "/",
      { headers: headers }
    );
  }
  // searchprojectList(val:string,userProfileId:number,type:string) {
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   return this.http.get(API_URL+'UserprojectList/'+userProfileId+'/?search='+val+'/'+type, {headers: headers})
  // }
  getprojectList(userProfileId: number, val: string, type: string) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL +
        "UserprojectList/" +
        userProfileId +
        "/?search=" +
        val +
        type,
      { headers: headers }
    );
  }

  searchfeedbackList(val: string, userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "UserfeedbackList/" + userProfileId + "/?search=" + val,
      { headers: headers }
    );
  }
  getfeedbackList(userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "UserfeedbackList/" + userProfileId + "/",
      { headers: headers }
    );
  }

  getQuestionList(project, userProfileId: number) {
    const headers = new HttpHeaders();
    const body = { "project": project, "userProfileId": userProfileId }
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'getQuestionList/', body, { headers: headers })
  }

  selectProjectListOfAgent(AgentId: number, name: string) {

    const headers = new HttpHeaders();
    const body = { "AgentId": AgentId, "name": name }
    headers.append('Content-Type', 'application/json');
    return this.http.post(this.API_URL + 'getProjects/', body, { headers: headers })

  }

  missedCallReport(userProfileId: number, fromDate, toDate, usertype, callDirection, search, pageNumberData, from_day: string = null, download: string = "", tag_filter) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let url = `${this.API_URL}missedCallReport/${userProfileId}/?from=${fromDate}&to=${toDate}&userType=${usertype}&callDirection=${callDirection}&search=${search}&page=${pageNumberData}&download=${download}&tag_filter=${tag_filter}`;
    if (from_day) {
      url += `&from_day=${from_day}`;
    }
    return this.http.get(url, { headers: headers });
  }


  vbReport(userProfileId: number, fromDate, toDate, userType) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    return this.http.get(this.API_URL + 'vbReport/' + userProfileId + '/?from=' + fromDate + '&to=' + toDate + '&userType=' + userType, { headers: headers });
  }

  selectAgentUnderProject(ProjectId) {
    const headers = new HttpHeaders();
    const body = { ProjectId: ProjectId };
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "getProjects/", body, {
      headers: headers,
    });
  }

  getDownloadallVoicezip(urls) {
    const headers = new HttpHeaders();
    const body = { urls: urls };
    return this.http.post(
      this.API_URL + "usermanagement/GetAllCallRecords/",
      body,
      { responseType: "blob" }
    );
  }

  download() {
    return this.http.get(this.API_URL + "InboundReport", {
      responseType: "blob",
      reportProgress: true,
      observe: "events",
    });
  }

  createContact(data) {
    const headers = new HttpHeaders();
    const body = data;
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "router/customers/", body, {
      headers: headers,
    });
  }

  getcustomers(user_id, search, userType, filterData) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let params = new HttpParams()
      .set("user_id", user_id)
      .set("search", search)
      .set("userType", userType)
      .set("filterData", filterData);

    return this.http.get(this.API_URL + "router/customers/", {
      headers: headers,
      params: params,
    });
  }

  uploadCustomerFile(data) {
    const headers = new HttpHeaders();
    const body = data;
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "upload-customer-data/", body, {
      headers: headers,
    });
  }

  getcontact(contactid, user_id, user_type) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let params = new HttpParams()
      .set("user_id", user_id)
      .set("userType", user_type);

    return this.http.get(this.API_URL + "router/customers/" + contactid, {
      headers: headers,
      params: params,
    });
  }

  deletecustomer(body, user_profileid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.request(
      "delete",
      this.API_URL + "router/customers/" + user_profileid + "/",
      { headers: headers, body: body }
    );
  }

  updatecontact(body, contactid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.put(
      this.API_URL + "router/customers/" + contactid + "/",
      body,
      { headers: headers }
    );
  }

  getcontactcallinfo(data) {
    const headers = new HttpHeaders();
    const body = data;
    headers.append("Content-Type", "application/json");
    return this.http.post(this.API_URL + "customer_call_log/", body, {
      headers: headers,
    });
  }

  getAllTags(userProfileId: number) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get(
      this.API_URL + "router/tags/?user_id=" + userProfileId,
      { headers: headers }
    );
  }

  createNewTag(userProfileId: number, tagName) {
    const headers = new HttpHeaders();
    const body = { tagName: tagName };
    headers.append("Content-Type", "application/json");
    return this.http.post(
      this.API_URL + "router/tags/?user_id=" + userProfileId,
      body,
      { headers: headers }
    );
  }

  deleteOneTag(userProfileId: number, tagName) {
    const headers = new HttpHeaders();
    const body = { tagName: tagName };
    headers.append("Content-Type", "application/json");
    return this.http.request(
      "delete",
      this.API_URL + "router/tags/" + userProfileId + "/",
      { headers: headers, body: body }
    );
  }

  updateTagBCustomer(
    username: string,
    email: any,
    userNumber: string,
    userProfileId: number,
    tag,
    permission: string,
    user_id: string,
    custom_field_values: any
  ) {
    const headers = new HttpHeaders();
    const body = {
      name: username,
      number: userNumber,
      permission: permission,
      user: userProfileId,
      tag: tag,
      custom_field_values: custom_field_values,
      email: email,
    };
    headers.append("Content-Type", "application/json");
    return this.http.put(
      this.API_URL + "router/customers/" + user_id + "/",
      body,
      { headers: headers }
    );
  }

  deleteTagBCustomer(
    username: string,
    userNumber: string,
    userProfileId: number,
    tag
  ) {
    const headers = new HttpHeaders();
    const body = { name: username, number: userNumber, tag: tag };
    headers.append("Content-Type", "application/json");
    return this.http.request(
      "delete",
      this.API_URL + "router/customers/" + userProfileId + "/",
      { headers: headers, body: body }
    );
  }
}
