import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { InboundRouteService } from '../inbound-route/inbound-route.service';
import { QueuemanagementService } from '../queuemanagement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IvrService } from '../ivr/ivr.service';
import { ExtensionService } from '../extension/extension.service';
import { VoiceRecordService } from '../voice-record/voice-record.service';
import Swal from 'sweetalert2';
import { NotificationService } from '../notification/notification.service';
import * as moment from 'moment'
import { stat } from 'fs';
import { startTimeRange } from '@angular/core/src/profile/wtf_impl'; 
import { parse } from 'querystring';
import { VoicemailService } from '../voicemail/voicemail.service';

@Component({
  selector: '[app-week-days]',
  templateUrl: './week-days.component.html',
  styleUrls: ['./week-days.component.css']
})
export class WeekDaysComponent implements OnInit {
  timeId:number = 0
  keyword:string = 'name';
  TimeConditionForm:FormGroup;
  destiny:boolean = true;
  destinationList:any;
  destination:string = '';
  destinationInitail:string = '';
  destinyLabel:string = '';
  voiceFileInitial:string = '';
  @Input() timeConditionSetId:number;
  @Input() timeCondtionData:any;
  voicelist:any = new Array();
  destinationNameInitial:string = ''
  formSubmit:boolean = false;
  @Output() parentEmitter = new EventEmitter<any>();
  @Input() userID:number;



  constructor(
    private loginservice: LoginService,
    private inboundservice: InboundRouteService,
    private queueservice: QueuemanagementService,
    private route: Router,
    private activeroute: ActivatedRoute,
    private ivrservice: IvrService,
    private extensionservice: ExtensionService,
    private voicerecodrservice: VoiceRecordService,
    private notifyservice: NotificationService,
    private voicemailservice: VoicemailService

  ) { }


  ngOnInit() {
    this.TimeConditionForm = new FormGroup({
      weekDay: new FormControl('0'),
      startTime: new FormControl(''),
      endTime: new FormControl(''),
      destinationType: new FormControl('0'),
      destinationName: new FormControl(''),
      timeConditionSet: new FormControl(''),
    });

    if(this.timeCondtionData) {
      this.timeId = this.timeCondtionData.id
      if(this.timeCondtionData.destinationType == '1'){
        this.ivrservice.GetIVRList(this.userID).subscribe(
          (data:any) =>{
            this.destinationList = []
            this.destinationList = data
          }
        )
      }else if(this.timeCondtionData.destinationType == '2'){
        this.queueservice.getQueues(this.userID).subscribe(
          (data:any) =>{
            this.destinationList = []
            this.destinationList = data
          }
        )
      }else if(this.timeCondtionData.destinationType == '3'){
        this.voicerecodrservice.getVoiceRecordList(this.userID,0).subscribe(
          (data:any) =>{
            this.voicelist = data
            for (let voiceData of this.voicelist){
              if(voiceData.fileUrl == this.timeCondtionData.destinationName) {
                this.voiceFileInitial = voiceData.name
              }
            }
          }
        )
      }else if(this.timeCondtionData.destinationType == '5'){
        this.extensionservice.searchInitialExtension(this.userID).subscribe(
          (data:any) =>{
            if(data){
              this.destinationList = []
              for (let i = 0; i < data.length; i++) {
                if(data[i].didNumber !== undefined) {
                  this.destinationList.push(data[i].didNumber)
                }   
              }
              // console.log('search extension list', this.destinationList)
            }
          }
        )
      }
      else if(this.timeCondtionData.destinationType == '10') {
        this.voicemailservice.getVoicemailTemplate(this.userID).subscribe(
          (data:any) => {
            this.destinationList = []
            this.destinationList = data
          }
        )
      }
      else{
        this.destinationList = []
        this.TimeConditionForm.value.destinationName = ''
      }
      this.TimeConditionForm.setValue({
        weekDay: this.timeCondtionData.weekDay,
        startTime: this.timeCondtionData.startTime, 
        endTime: this.timeCondtionData.endTime, 
        destinationType: this.timeCondtionData.destinationType,
        destinationName: this.timeCondtionData.destinationName,
        timeConditionSet: this.timeCondtionData.timeConditionSet,
      })
      this.onDestinyType();
      this.destinationNameInitial = this.timeCondtionData.destinationName
    }
  }

  get f() { return this.TimeConditionForm.controls; }


  onDestinyType() {
    if(this.TimeConditionForm.value.destinationType == '1') {
      this.TimeConditionForm.value.destinationName = ''
      this.destinyLabel = 'Select IVR';
      this.destiny = true;
    }else if(this.TimeConditionForm.value.destinationType == '2') {
      this.destinyLabel = 'Select Queue'
      this.destiny = true;
    }else if(this.TimeConditionForm.value.destinationType == '3'){
      this.TimeConditionForm.value.destinationName = ''
      this.destiny = false;
    }else if(this.TimeConditionForm.value.destinationType == '5'){
      this.TimeConditionForm.value.destinationName = ''
      this.destinyLabel = 'Select Extension';
      this.destiny = true;
    }
    else if(this.TimeConditionForm.value.destinationType == '10'){
      this.TimeConditionForm.value.destinationName = ''
      this.destinyLabel = 'Select Voicemail';
      this.destiny = true
    }
    else{
      this.TimeConditionForm.value.destinationName = ''
    }
  }


  selectQueue(item: { name: any; }) {
    this.TimeConditionForm.value.destinationName = item.name
  }
 
  onChangeSearchQueue(val: string) {
    if(this.TimeConditionForm.value.destinationType == '1'){
      this.ivrservice.searchIVR(val ,this.userID).subscribe(
        (data:any) =>{
          this.destinationList = data
          // console.log('search ivr list', this.destinationList)
        }
      )
    }else if(this.TimeConditionForm.value.destinationType == '2'){
      this.queueservice.searchQueue(val ,this.userID).subscribe(
        (data:any) =>{
          this.destinationList = data
          // console.log('search queue list', this.destinationList)
        }
      )
    }else if(this.TimeConditionForm.value.destinationType == '5'){
      this.extensionservice.searchExtension(val ,this.userID).subscribe(
        (data:any) =>{
          for(let e of data){
            if(e != undefined) {
              this.destinationList.push(e.didNumber)
            }    
          }
          // console.log('search extension list', this.destinationList)
        }
      )
    }
    else if(this.TimeConditionForm.value.destinationType == '10'){
      this.voicemailservice.getVoicemailTemplate(this.userID).subscribe(
        (data:any) =>{
          this.destinationList = data
        }
      )
    }
  }
  
  onFocusedQueue(e: any){
    if(this.TimeConditionForm.value.destinationType == '2'){
      this.queueservice.getQueues(this.userID).subscribe(
        (data:any) =>{
          this.destinationList = []
          this.destinationList = data
          // console.log('queue focused list', this.destinationList)
        }
      )
    }else if(this.TimeConditionForm.value.destinationType == '1'){
      this.ivrservice.GetIVRList(this.userID).subscribe(
        (data:any) =>{
          this.destinationList = []
          this.destinationList = data
        }
      )
    }else if(this.TimeConditionForm.value.destinationType == '5'){
      this.extensionservice.searchInitialExtension(this.userID).subscribe(
        (data:any) =>{
          if(data){
            this.destinationList = []
            for (let i = 0; i < data.length; i++) {
              if(data[i].didNumber !== undefined) {
                this.destinationList.push(data[i].didNumber)
              }   
            }
          }
        }
      )
    }
    else if(this.TimeConditionForm.value.destinationType == '10'){
      this.voicemailservice.getVoicemailTemplate(this.userID).subscribe(
        (data:any) =>{
          this.destinationList = []
          this.destinationList = data
        }
      )
    }
    else{
      this.destinationList = []
      this.TimeConditionForm.value.destinationName = ''
    }
  }

  onClearDestination() {
    this.TimeConditionForm.value.destinationName = ''
  }

  voiceFilePath(data: any) {
    this.TimeConditionForm.value.destinationName = data
  }

  onAdd() {

    if(this.TimeConditionForm.value.weekDay == '0'){
      Swal.fire("Oops ...!", "Please select valid week day", "warning");
    }else if(this.TimeConditionForm.value.startTime == ''){
      Swal.fire("Oops ...!", "Please select valid start time", "warning");
    }else if(this.TimeConditionForm.value.endTime == ''){
      Swal.fire("Oops ...!", "Please select valid end time", "warning");
    }else if(this.TimeConditionForm.value.destinationType == '0'){
      Swal.fire("Oops ...!", "Please select valid destination type", "warning");
    }else if(this.TimeConditionForm.value.destinationName == ''){
      Swal.fire("Oops ...!", "Please select valid destination name", "warning");
    }else if  (moment(this.TimeConditionForm.value.startTime, 'HH:mm A').isBefore(moment(this.TimeConditionForm.value.endTime, 'HH:mm A')) == false){
      Swal.fire("Oops ...!", "Select greater than start time", "warning");
    }else{
      this.inboundservice.getTimeConditionsList(this.timeConditionSetId).subscribe(
        (res:any) => {

          if(res.length != 0) {
            this.formSubmit = false
            for(let timeData of res){
              if(timeData.weekDay == this.TimeConditionForm.value.weekDay && timeData.id != this.timeId){
                
                if(timeData.startTime <= this.getParsedTime(this.TimeConditionForm.value.startTime)){

                  if(timeData.endTime < this.getParsedTime(this.TimeConditionForm.value.startTime)){

                    if(this.getParsedTime(this.TimeConditionForm.value.startTime) < this.getParsedTime(this.TimeConditionForm.value.endTime)){
                      
                      this.TimeConditionForm.value.startTime = this.getParsedTime(this.TimeConditionForm.value.startTime)
                      this.TimeConditionForm.value.endTime = this.getParsedTime(this.TimeConditionForm.value.endTime)
                      this.formSubmit = true
                    }else{
                      Swal.fire("Oops ...!", "Select greater than start time", "warning");
                      this.formSubmit = false;
                      break;
                    }
                  }else{
                    Swal.fire("Oops ...!", "Already configured start time", "warning");
                    this.formSubmit = false;
                    break;
                  }


                }else{
                  if(timeData.startTime > this.getParsedTime(this.TimeConditionForm.value.endTime)){
                    if(this.getParsedTime(this.TimeConditionForm.value.startTime) < this.getParsedTime(this.TimeConditionForm.value.endTime)){
                      this.TimeConditionForm.value.startTime = this.getParsedTime(this.TimeConditionForm.value.startTime)
                      this.TimeConditionForm.value.endTime = this.getParsedTime(this.TimeConditionForm.value.endTime)
                      this.formSubmit = true;
                    }else{
                      Swal.fire("Oops ...!", "Select greater than start time", "warning");
                      this.formSubmit = false;
                      break;
                    }
                  }else{
                    Swal.fire("Oops ...!", "Already configured end time", "warning");
                    this.formSubmit = false;
                    break;
                  }
                }
              }else{
                this.TimeConditionForm.value.startTime = this.getParsedTime(this.TimeConditionForm.value.startTime)
                this.TimeConditionForm.value.endTime = this.getParsedTime(this.TimeConditionForm.value.endTime)
                this.formSubmit = true;
              } 
            }
          }else{
            this.TimeConditionForm.value.startTime = this.getParsedTime(this.TimeConditionForm.value.startTime)
            this.TimeConditionForm.value.endTime = this.getParsedTime(this.TimeConditionForm.value.endTime)
            this.formSubmit = true;
          }
          if(this.formSubmit == true){
            this.TimeConditionForm.value.startTime = this.getParsedTime(this.TimeConditionForm.value.startTime)
            this.TimeConditionForm.value.endTime = this.getParsedTime(this.TimeConditionForm.value.endTime)
            this.timeObject();
          }
        }
      )

    }  
  }

  timeObject() {
    this.TimeConditionForm.value.timeConditionSet = this.timeConditionSetId
    // console.log('++++++++++++++++++++===============',this.TimeConditionForm.value)
    if(this.timeId == 0) {
      this.inboundservice.addTimeCondition(this.TimeConditionForm.value).subscribe(
        (res:any) => {
          if(res && res.id) {
            this.timeId = res.id
            this.notifyservice.sendNotification('Created', 'Time condition create successfully', 'is-success')
          }
        }
      )
    }else{
      this.inboundservice.updateTimeCondition(this.timeId, this.TimeConditionForm.value).subscribe(
        (res:any) => {
          if(res && res.id) {
            this.timeId = res.id
            this.notifyservice.sendNotification('Updated', 'Time condition update successfully', 'is-success')
          }
        }
      )
    }
  }

  onDeleteTime(id: any) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'Time Interval Deleted',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete it!',
      cancelButtonText: 'No, Cancel it!'
    }).then((result) => {
      if (result.value) {
        this.inboundservice.deleteTimeCondition(id).subscribe(
          (res:any) => {
            this.notifyservice.sendNotification('Deleted', 'Time Interval delete successfully', 'is-success')
            this.parentEmitter.emit(this.timeConditionSetId);
          }
        )
      }else{
        this.notifyservice.sendNotification('Cancelled', 'Time Interval delete has been cancelled', 'is-danger')
      }
    });
  }

  getParsedTime(time) {
    return moment(time, 'h:mm A').format('HH:mm:ss')
  }

}
