import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InboundRouteService } from '../inbound-route.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { LoginService } from 'src/app/login.service';
import { NotificationService } from 'src/app/notification/notification.service';
import Swal from 'sweetalert2';

@Component({
  selector: '[app-addtimecondition]',
  templateUrl: './addtimecondition.component.html',
  styleUrls: ['./addtimecondition.component.css']
})
export class AddtimeconditionComponent implements OnInit {
  public TimeConditionSetForm: FormGroup;
  public id: number;
  userData:any;
  TimeConditionData:any;
  Num:number = 0;
  TimeCount:any = new Array();
  TimeConditionValue:any;
  isAdmin:string = 'false'
  UserProfileId:number = 0;
  customerInitial:any;
  customerList:any = new Array();
  isEdit:boolean = true;
  existedTimeCondition:any


  constructor(
    private loginservice: LoginService,
    private inboundservice: InboundRouteService,
    private activeroute: ActivatedRoute,
    private route: Router,
    private notifyservice: NotificationService,
    ) { }

  ngOnInit() {
    this.isAdmin = localStorage.getItem('isAdmin')
    this.userData = this.loginservice.getUserdata(false);
    if(this.isAdmin == 'false'){
      this.UserProfileId = this.userData.id
    }
    this.TimeConditionSetForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      user: new FormControl('')
    })
    this.id = +this.activeroute.snapshot.paramMap.get('id');
    if (this.id != 0) {
      this.isEdit = false;
      this.inboundservice.getTimeConditionSet(this.id).subscribe(
        (res:any) => {
          this.TimeConditionSetForm.setValue({
            name: res.name,
            description: res.description,
            user: res.user
          });
          this.UserProfileId = res.user
          this.loginservice.getCustomerList().subscribe(
            (data:any) => {
              this.customerList = data
              for (let customerData of this.customerList){
                if(customerData.id == res.user) {
                  this.customerInitial = customerData.name
                }
              }
            }
          )
        }
      )
      this.inboundservice.getTimeConditionsList(this.id).subscribe(
        (res:any) => {
          this.TimeConditionData = res
          this.Num = this.TimeConditionData.length
          this.TimeCount = []
          if(this.Num != 0) {
            for (let i = 0; i < this.Num; i++) {
              if (this.TimeConditionData.length >= i){
                this.TimeConditionValue = this.TimeConditionData[i]
              }else{
                this.TimeConditionValue = undefined
              }
              this.TimeCount.push({
                'orderId': 'TimeCondition'+ (i+ 1).toString(),
                'TimeConditionValue': this.TimeConditionValue
              })
            }
          }else {
            this.Num = 1
            for (let i = 0; i < this.Num; i++) {
              this.TimeCount.push({
                'orderId': 'LevelOne'+ (i+ 1).toString(),
                'TimeConditionValue': undefined
              })
            }
          }
        }
      )
    }
  }

  customerEvent(data) {
    this.UserProfileId = data
  }

  get f() { return this.TimeConditionSetForm.controls; }

  onSubmit() {
    if(this.isAdmin == 'true' && this.UserProfileId == 0) {
        Swal.fire('Oops..!', 'Valid customer Select', 'warning');
    }else if(this.TimeConditionSetForm.value.name == '') {
      Swal.fire('Oops..!', 'Please type valid name', 'warning');
    }else{
      this.TimeConditionSetForm.value.user = this.UserProfileId

      this.inboundservice.addTimeConditionSet(this.TimeConditionSetForm.value).subscribe(
        
        (res:any) => {
          this.existedTimeCondition = res;
				  // alert(JSON.stringify(res));
          this.existedTimeCondition= res;
				  if(this.existedTimeCondition['status'] == "exist"){
            Swal.fire('Oops..!', 'Please change the TimeCondition Name, Already Exist', 'warning')
          }
          else{
          // alert(JSON.stringify(res));
        
          if(res && res.id) {
            this.isEdit = false;
            this.notifyservice.sendNotification('Created', 'Time condition set created successfully', 'is-success')
            this.id = res.id
            this.loginservice.getCustomerList().subscribe(
              (data:any) => {
                this.customerList = data
                for (let customerData of this.customerList){
                  if(customerData.id == res.user) {
                    this.customerInitial = customerData.name
                  }
                }
              }
            )
          }else {
            this.notifyservice.sendNotification('Error Occured!', '', 'is-danger');
          }
        }}
      )
    }
  }

  onUpdate() {
    if(this.isAdmin == 'true' && this.UserProfileId == 0) {
        Swal.fire('Oops..!', 'Valid customer Select', 'warning');
    }else if(this.TimeConditionSetForm.value.name == '') {
      Swal.fire('Oops..!', 'Please type valid name', 'warning');
    }else{
      this.TimeConditionSetForm.value.user = this.UserProfileId
      this.inboundservice.updateTimeConditionSet(this.id, this.TimeConditionSetForm.value).subscribe(
        (res:any) => {
          if(res && res['id']) {
            this.notifyservice.sendNotification('Updated', 'Time condition set updated Successfully', 'is-success')
          }else {
            swal.fire('Error Occured!');
          }
        }
      )
    }
    
  }

  onAddSet() {
    this.inboundservice.getTimeConditionsList(this.id).subscribe(
      (data:any) => {
        this.TimeConditionData = data
        this.Num = this.Num + 1
        this.TimeCount = []
        if(this.TimeConditionData) {
          for (let i = 0; i < this.Num; i++) {
            if (this.TimeConditionData.length >= i){
              this.TimeConditionValue = this.TimeConditionData[i]
            }else{
              this.TimeConditionValue = undefined
            }
            this.TimeCount.push({
              'orderId': 'TimeCondition'+ (i+ 1).toString(),
              'TimeConditionValue': this.TimeConditionValue
            })
          }
        }else {
          for (let i = 0; i < this.Num; i++) {
            this.TimeCount.push({
              'orderId': 'LevelOne'+ (i+ 1).toString(),
              'TimeConditionValue': undefined
            })
          }
        }
      }
    )
  }

  parentEmitterData(response) {
    this.inboundservice.getTimeConditionsList(response).subscribe(
      (res:any) => {
        this.TimeConditionData = res
        this.Num = this.TimeConditionData.length
        this.TimeCount = []
        if(this.Num != 0) {
          for (let i = 0; i < this.Num; i++) {
            if (this.TimeConditionData.length >= i){
              this.TimeConditionValue = this.TimeConditionData[i]
            }else{
              this.TimeConditionValue = undefined
            }
            this.TimeCount.push({
              'orderId': 'TimeCondition'+ (i+ 1).toString(),
              'TimeConditionValue': this.TimeConditionValue
            })
          }
        }else {
          this.Num = 1
          for (let i = 0; i < this.Num; i++) {
            this.TimeCount.push({
              'orderId': 'LevelOne'+ (i+ 1).toString(),
              'TimeConditionValue': undefined
            })
          }
        }
      }
    )
  }

}
