import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { SharedService } from "src/global";

@Injectable({
    providedIn: "root"
})

export class WhatsappService{

    constructor(
        private sharedService: SharedService,
        private http: HttpClient
    ) { }
    
    BASE_URL = this.sharedService.getGlobalVar();
    header = new HttpHeaders().set("Content-Type", "application/json")

    getWhatsappUsersList(qParams: any[]) {

        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + "wb/whatsapp-users/", {headers: this.header, params: params})
    }

    getWhatsappUser(qParams: any[]) {
        const params = new HttpParams()
        return this.http.get(this.BASE_URL + `wb/whatsapp-users/`, { headers: this.header})
    }

    addWhatsappUser(data: any) {
        return this.http.post(this.BASE_URL + "wb/whatsapp-users/", data, {headers: this.header})
    }

    updateWhatsappUser(data: any) {
        return this.http.put(this.BASE_URL + `wb/whatsapp-users/${data.user_id}/`, data, {headers: this.header})
    }

    deleteWhatsappUser(user_id: string) {
        return this.http.delete(this.BASE_URL + `wb/whatsapp-users/${user_id}/`, { headers: this.header })
    }

    fetchWhatsappAccounts(data: any) {        
        return this.http.post(this.BASE_URL + `wb/whatsapp-accounts/`, data, { headers: this.header})
    }

    getWhatsappAccounts(qParams: any[]) {
        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + `wb/whatsapp-accounts/`, { headers: this.header, params: params })
    }
    getTemplatesList(qParams: any[]) {
        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + `wb/whatsapp-templates/`, { headers: this.header, params: params })
    }

    fecthTemplates(data: any) {
        return this.http.post(this.BASE_URL + `wb/whatsapp-templates/`, data, { headers: this.header })
    }


    updateTemplate(data: any) {
        return this.http.put(this.BASE_URL + `wb/whatsapp-templates/${data.id}/`, data, { headers: this.header })
    }

    getMessageTypeList() {
        return this.http.get(this.BASE_URL + `wb/message-types/`, { headers: this.header })
    }

    addTemplateContent(data: any) {
        return this.http.post(this.BASE_URL + `wb/template-content/`, data, {headers: this.header})
    }

    getTemplateContentList(qParams: any[]) {
        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + `wb/template-content/`, {headers: this.header, params: params})
    }

    updateTemplateContent(data: any) {
        return this.http.put(this.BASE_URL + `wb/template-content/${data.id}/`, data, { headers: this.header })
    }

    deleteTemplateContent(id: string) {
        return this.http.delete(this.BASE_URL + `wb/template-content/${id}/`, { headers: this.header })
    }

    addTemplateMedia(data: any) {
        return this.http.post(this.BASE_URL + `wb/template-media/`, data, { headers: this.header })
    }

    getTemplateMediaList(qParams: any[]) {
        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + `wb/template-media/`, { headers: this.header, params: params })
    }

    updateTemplateMedia(data: any) {
        return this.http.put(this.BASE_URL + `wb/template-media/${data.id}/`, data, { headers: this.header })
    }

    deleteTemplateMedia(id: string) {
        return this.http.delete(this.BASE_URL + `wb/template-media/${id}/`, { headers: this.header })
    }

    getMessageList(qParams: any[]) {
        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + `wb/whatsapp-messages/`, { headers: this.header, params: params })
    }

    getDIDList(userId: string) {
        return this.http.get(this.BASE_URL + `DIDList/${userId}`, {headers: this.header})
    }

    getVsipAgentList(data: any) {
        return this.http.post(this.BASE_URL + `getVirtualSipList/`, data, {headers: this.header})
    }

    addWhatsappSettings(data: any) {
        return this.http.post(this.BASE_URL + 'wb/whatsapp-settings/', data, {headers: this.header})
    }
    
    getWhatsappSettingsList(qParams: any[]) {
        let params = new HttpParams()
        qParams.forEach(param => {
            params = params.set(param.key, param.value)
        })
        return this.http.get(this.BASE_URL + `wb/whatsapp-settings/`, {params: params, headers: this.header})
    }

    updateWhatsappSettings(data: any) {
        return this.http.put(this.BASE_URL + `wb/whatsapp-settings/${data.id}/`, data, {headers: this.header})
    }

    deleteWhatsappSettings(id: string) {
        return this.http.delete(this.BASE_URL + `wb/whatsapp-settings/${id}/`, {headers: this.header})
    }

    addIVRSettings(data: any) {
        return this.http.post(this.BASE_URL + `wb/whatsapp-ivr-settings/`, data, {headers: this.header})
    }

    getIVRList(user_id: string) {
        return this.http.get(this.BASE_URL + `ivrList/${user_id}/`, { headers: this.header })
    }

    getIVRSettingsList(whatsapp_user_id: string) {
        let params = new HttpParams().set("whatsapp_user_id", whatsapp_user_id)
        return this.http.get(this.BASE_URL + `wb/whatsapp-ivr-settings/`, {params: params, headers: this.header})
    }

    updateIVRSettings(data: any) {
        return this.http.put(this.BASE_URL + `wb/whatsapp-ivr-settings/${data.id}/`, data, {headers: this.header})
    }

    deleteIVRSettings(id) {
        return this.http.delete(this.BASE_URL + `wb/whatsapp-ivr-settings/${id}/`, {headers: this.header})
    }


}

