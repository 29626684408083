import { Component, OnInit, Input } from "@angular/core";
import { NotificationService } from "src/app/notification/notification.service";
import { LoginService } from "src/app/login.service";
import { ReportService } from "../inbound-report/report.service";
import {FormBuilder, FormGroup, Validators, FormControl,} from "@angular/forms";
import Swal from "sweetalert2";
import { CustomFieldService } from "./custom-field/custom-field.service";
import { ExcelService } from "../inbound-report/excel.service";
import { BroadcastService } from "src/app/voicebroadcast/voicebroadcast.service";
import { Router, ActivatedRoute } from "@angular/router";
import { API_URL } from "src/global";
@Component({
  selector: "app-addressbook",
  templateUrl: "./addressbook.component.html",
  styleUrls: ["./addressbook.component.css"],
})
export class AddressbookComponent implements OnInit {
  calllogdata: any = new Array();
  adressbookdata: any = new Array();
  adressbookdata_display: any = new Array();
  followUpPopup: boolean = false;
  activeTab: 'addFollowUp' | 'followUpList' = 'followUpList';
  editMode: boolean = false;
  isFollowUpAuto: boolean = false;
  current_page: number = 1;
  total_page: any;
  last: number;
  userData: any;
  userType: any;
  permission: string = "";
  customer: string = "";
  selectedFile: File;
  customer_num: string = "";
  customer_id: string = "";
  showcontact: boolean = false;
  uploadcustomers: boolean = false;
  contactForm: FormGroup;
  cont_error: string = "";
  sheetuploaderrorResponse: any[] = [];
  status_color = {
    NOANSWER: { color: "", alias: "no answer" },
    BUSY: { color: "", alias: "busy" },
    ANSWERED: { color: "", alias: "answerd" },
    NOINPUT: { color: "", alias: "no input" },
    others: { color: "", alias: "" },
  };
  isHidden: boolean = false;
  newTag: string;
  tagList: any = new Array();
  isDropdownOpen: boolean[] = [];
  addressbook_page: any = 1;
  total_pages: any;
  adressbookper_page = 200;
  call_log_loading: boolean = false;
  loadingText: string = "Fetching call logs";
  loadingInterval: any;

  // Settingsforcomment
  commentopen: boolean = false;
  comment: any;
  call_id: any;
  public menus: any;

  customFieldData: [] = [];
  customFieldValuesForm: FormGroup;

  customerExcelData: any = new Array();
  filterData: any;
  search: any;
  public newarray: any = [
    {
      SlNumber: "",
      Extension: "",
      Name: "",
      PhoneNumber: "",
      Description: "",
      User: "",
    },
  ];


  @Input() public callRecordSettingType: string;
  @Input() public daysWithin: number;

  newrecord: any;
  date: any;
  currentDate: any;
  newdate: any;

  constructor(
    private reportservice: ReportService,
    private notifyService: NotificationService,
    private loginservice: LoginService,
    private formBuilder: FormBuilder,
    private notifyservice: NotificationService,
    private customfieldservice: CustomFieldService,
    private excelservice: ExcelService,
    private broadcastservice: BroadcastService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }


  // ngOnInit() {
  //   console.log("----testing follow up inside addressbook component-----")
  //   this.route.params.pipe(distinctUntilChanged((prev, curr) => prev['customerId'] === curr['customerId'])).subscribe(params => {
  //     const customerId = params['customerId']; // 'id' matches the route parameter
  //     const followUpId = params['followUpId'];
  //     console.log('customerId', customerId)
  //     // this.openFollowUpPopup(customerId);

  //     if (customerId != undefined) {
  //       console.log('customerId', customerId)
  //       this.customer_id = customerId;
  //       this.isFollowUpAuto = true;
  //       console.log('-----------------inside if------------------')
  //       // this.openFollowUpPopup(customerId);
  //     }
  //   });

  //   this.userData = this.loginservice.getUserdata(true);
  //   this.userType = localStorage.getItem('userType');
  //   this.FetchCustomerData();
  //   console.log('1111111111111111111111111111')

  //   this.contactForm = this.formBuilder.group({
  //     name: ['', Validators.required],
  //     number: ['', [Validators.required, Validators.pattern(/^91\d{8}$/)]],
  //     // email: ['', [Validators.email]]
  //   });
  //   this.customFieldValuesForm = this.formBuilder.group({})
  //   console.log('2222222222222222222222222222222222')

  // }


  // openFollowUpPopup(customer_id) {
  //   this.isFollowUpAuto = true;
  //   this.customer_id = customer_id;

  // }


  ngOnInit() {
    this.userData = this.loginservice.getUserdata(true);
    this.userType = localStorage.getItem('userType');
    this.FetchCustomerData();

    // Initialize your forms
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      number: ['', [Validators.required, Validators.pattern(/^91\d{8}$/)]],
      // email: ['', [Validators.email]]
    });
    this.customFieldValuesForm = this.formBuilder.group({});
  }

  isWithinDays(callTime: string): boolean {
    this.userData = this.loginservice.getUserdata(true);

    const daysWithin = Number(this.userData.days_within) || 0;

    if (daysWithin <= 0) {
      return true; // Show audio if no specific daysWithin is set
    }

    const callDate = new Date(callTime);
    const currentDate = new Date();
    const differenceInDays = Math.floor((currentDate.getTime() - callDate.getTime()) / (1000 * 3600 * 24));

    return differenceInDays <= daysWithin;
  }

  setPage(pageNumber: number) {
    this.addressbook_page = pageNumber;
    this.getCurrentPageData(pageNumber);
  }

  getPageNumbers(): number[] {
    const totalPages = Math.ceil(
      this.adressbookdata.length / this.adressbookper_page
    );
    this.total_pages = totalPages;
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  }

  // Method to get the data for the current page
  getCurrentPageData(pageNumber: any) {
    const startIndex = (pageNumber - 1) * this.adressbookper_page;
    const endIndex = startIndex + this.adressbookper_page;
    console.log("startIndex,endIndex", startIndex, endIndex);
    this.adressbookdata_display = this.adressbookdata.slice(
      startIndex,
      endIndex
    );
  }

  DownloadVoice(vfile, date) {
    let voice_url =
      API_URL + "uploads/downloadedWaveFile/" + vfile.replace(".gsm", ".wav");
    var html_con =
      '<audio class="is-6" controls="" controlslist="nodownload" style="height: 40px; width: 32rem;">' +
      "  <source src=" +
      voice_url +
      " </audio>";
    this.reportservice.getcallrecord(vfile, date).subscribe((res: any) => {
      document.getElementById("audio_" + vfile).innerHTML = html_con;
    });
  }

  DownloadVoiceMail(vfile, date) {
    let voice_url =
      API_URL +
      "uploads/downloadedVoiceMailFile/" +
      vfile.replace(".gsm", ".wav");
    var html_con =
      '<audio class="is-6" controls="" controlslist="nodownload" style="height: 40px; width: 32rem;">' +
      "  <source src=" +
      voice_url +
      " </audio>";
    this.reportservice
      .getcallrecord(vfile, date, "voice_mail")
      .subscribe((res: any) => {
        document.getElementById("audio_" + vfile).innerHTML = html_con;
      });
  }

  Showcalllog(id) {
    this.call_log_loading = true;
    this.cycleLoadingText();
    let post_data = { customer_id: id };
    this.reportservice.getcontactcallinfo(post_data).subscribe((res: any) => {
      if (res.call_logs && res.call_logs.length > 0) {
        this.calllogdata = res.call_logs;
        this.call_log_loading = false;
        this.stopLoadingTextCycle();
      } else {
        setTimeout(() => {
          this.calllogdata = res.call_logs;
          this.call_log_loading = false;
          this.stopLoadingTextCycle();
        }, 1000);
      }
    });
  }

  searchContact(e) {
    this.FetchCustomerData(e.target.value);
  }

  sampleBroadcastSheet() {
    var newarray = [
      {
        name: "",
        number: "",
        email: "",
        permission: "",
      },
    ];

    this.customfieldservice
      .getCustomField(this.userData.id)
      .subscribe((response: any) => {
        this.customFieldData = response.custom_field_names;
        if (this.customFieldData && this.customFieldData.length > 0) {
          for (var i = 0; i < this.customFieldData.length; i++) {
            var propName: string = this.customFieldData[i];
            console.log("propName", propName);
            newarray[0][propName] = "";
          }
        }
        this.broadcastservice.downloadsamplesheet(
          newarray,
          "CustomerSampleSheet"
        );
      });
  }

  uploadCustomers() {
    this.showcontact = false;
    this.uploadcustomers = !this.uploadcustomers;
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onFileUpload(event) {
    const formData = new FormData();
    formData.append("customerfile", this.selectedFile);
    formData.append("userId", this.userData.id);

    this.reportservice.uploadCustomerFile(formData).subscribe(
      (res: any) => {
        if (res.status == 1) {
          if (res.invalid && res.invalid.length > 0) {
            this.sheetuploaderrorResponse = res.invalid;
          } else {
            this.sheetuploaderrorResponse = [];
            Swal.fire("", res.message, "success");
            this.showcontact = false;
            this.uploadcustomers = !this.uploadcustomers;
            this.FetchCustomerData();
          }
        } else {
          Swal.fire("", res.message, "warning");
        }
      },
      (error) => {
        Swal.fire(
          "",
          "Some Error occured contact bonvoice support team",
          "warning"
        );
      }
    );
  }

  AddContact(type, id = 0) {
    if (type == "update") {
      this.reportservice
        .getcontact(id, this.userData.id, this.userType)
        .subscribe((res: any) => {
          if (res.id) {
            this.contactForm.patchValue({
              name: res.name,
              email: res.email,
              number: res.number,
              userType: this.userType,
              permission: this.permission,
            });
          } else {
            this.notifyService.sendNotification(
              "",
              "Error in Getting conatct",
              "is-danger"
            );
          }
        });
    } else {
      this.contactForm = this.formBuilder.group({
        name: ["", Validators.required],
        number: ["", [Validators.required, Validators.pattern(/^\d{10}$/)]],
        email: ["", [Validators.email]],
        permission: ["", Validators.required],
      });

      if (this.userType == "agentUser" || this.userType == "dialerUser") {
        this.fetchCustomFields(this.userData.admin);
      } else {
        this.fetchCustomFields(this.userData.id);
      }
    }

    this.showcontact = !this.showcontact;
    this.uploadcustomers = false;
  }

  SubmitConatct() {
    if (this.contactForm.valid) {
      let data_post = this.contactForm.value;
      data_post.user = parseInt(this.userData.id);
      data_post.userType = this.userType;

      if (this.customFieldData) {
        data_post.custom_field_values = this.customFieldValuesForm.value;
      }

      this.CreateContact("", data_post);
    } else {
      console.log(
        "====FORM INVALID HEY===",
        this.contactForm.valid,
        this.contactForm.invalid
      );
      console.log(
        "======ERRORS=========",
        this.contactForm.get("number").errors
      );
    }
  }

  updateContact(id) {
    if (this.contactForm.valid) {
      let data_post = this.contactForm.value;
      data_post.user = parseInt(this.userData.id);
      this.CreateContact(id, data_post);
    } else {
      console.log(
        "====FORM INVALID HEY===",
        this.contactForm.valid,
        this.contactForm.invalid
      );
      console.log(
        "======ERRORS=========",
        this.contactForm.get("number").errors
      );
    }
  }

  CreateContact(con_id, data) {
    if (con_id) {
      // update contact
    } else {
      this.reportservice.createContact(data).subscribe((res: any) => {
        console.log("res.id -------------- ", res.id);
        if (res.id) {
          this.notifyService.sendNotification(
            "Success",
            "Contact Created Succesfully",
            "is-success"
          );
          this.FetchCustomerData();
          this.showcontact = false;
          this.customFieldValuesForm.reset();
        } else if (res.error) {
          this.cont_error = res.error;
          return;
        } else {
          this.notifyService.sendNotification(
            "Error",
            "Error in Creating conatct",
            "is-danger"
          );
          return;
        }
      });
    }
  }

  FetchCustomerData(search = "", filterData = "") {
    // var from=this.datePipe.transform(this.fromdate, 'yyyy-MM-dd');
    // var to=this.datePipe.transform(this.todate, 'yyyy-MM-dd');
    this.search = search;
    this.filterData = filterData;

    this.reportservice
      .getcustomers(
        this.userData.id,
        this.search,
        this.userType,
        this.filterData
      )
      .subscribe((res: any) => {
        this.adressbookdata = res;
        this.getCurrentPageData(1);
        if (this.adressbookdata[0].id) {
          this.customer = this.adressbookdata[0].name;
          this.customer_id = this.adressbookdata[0].id;
          this.Showcalllog(this.customer_id);
        }
      });
  }

  CloseComment() {
    this.commentopen = false;
    this.call_id = "";
    this.comment = "";
  }

  toggleHiddenDiv() {
    this.isHidden = !this.isHidden;
    this.getAllTags();
  }

  createTag() {
    this.reportservice
      .createNewTag(this.userData.id, this.newTag)
      .subscribe((res: any) => {
        this.newTag = "";
        this.getAllTags();
      });
  }

  getAllTags() {
    let user_id: any;

    if (this.userType == "agentUser" || this.userType == "dialerUser") {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportservice.getAllTags(user_id).subscribe((res: any) => {
      this.tagList = res[0].tagarray;
    });
  }

  deleteTag(tag: string) {
    Swal.fire({
      title: "Are you sure ?",
      text: "Deleting this tag will remove it from all contacts",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Delete it!",
      cancelButtonText: "No, Cancel it!",
    }).then((result) => {
      if (result.value) {
        this.reportservice
          .deleteOneTag(this.userData.id, tag)
          .subscribe((res: any) => {
            this.getAllTags();
            this.FetchCustomerData();
          });
      } else {
        this.notifyservice.sendNotification(
          "Cancelled",
          "Tag deletion has been cancelled",
          "is-danger"
        );
      }
    });
  }

  toggleDropdown(index: number) {
    this.isDropdownOpen[index] = !this.isDropdownOpen[index];
    this.getAllTags();
  }

  updateTagCustomer(tag: string, index: number) {
    if (!this.adressbookdata[index].tag.includes(tag)) {
      this.reportservice
        .updateTagBCustomer(
          this.adressbookdata[index].name,
          this.adressbookdata[index].email,
          this.adressbookdata[index].number,
          this.userData.id,
          tag,
          this.adressbookdata[index].permission,
          this.adressbookdata[index].id,
          this.adressbookdata[index].custom_field_values
        )
        .subscribe((res: any) => {
          this.isDropdownOpen[index] = false;
          this.adressbookdata[index] = res.user;
        });
    } else {
      this.isDropdownOpen[index] = false;
    }
  }

  deleteTagCustomer(tag: string, index: number) {
    let user_id: any;

    if (this.userType == "agentUser" || this.userType == "dialerUser") {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportservice
      .deleteTagBCustomer(
        this.adressbookdata[index].name,
        this.adressbookdata[index].number,
        user_id,
        tag
      )
      .subscribe((res: any) => {
        this.adressbookdata[index] = res.user;
      });
  }

  fetchCustomFields(user_id: any) {
    this.customfieldservice
      .getCustomField(user_id)
      .subscribe((response: any) => {
        this.customFieldData = response.custom_field_names;

        console.log("this.customFieldData", this.customFieldData);

        if (this.customFieldData && this.customFieldData.length > 0) {
          this.customFieldData.forEach((custom_field_names) => {
            this.customFieldValuesForm.addControl(
              custom_field_names,
              new FormControl("")
            );
          });
        }
      });
  }

  SendFilterData(filterData: any) {
    const StringifiedFilterData = JSON.stringify(filterData);
    this.FetchCustomerData("", StringifiedFilterData);
  }

  downloadAsExcel() {
    const searchText = this.search ? this.search : "";
    const filterDataText = this.filterData ? this.filterData : "";

    this.reportservice
      .getcustomers(this.userData.id, searchText, this.userType, filterDataText)
      .subscribe((res: any) => {
        const address_book_data = res;
        const CustomersData = address_book_data.map((customer: any) => {
          const { id, user, custom_field_values, ...rest_of_the_data } =
            customer;
          const each_custom_field_value = {
            ...rest_of_the_data,
            ...custom_field_values,
          };
          return each_custom_field_value;
        });
        this.excelservice.exportAsExcelFile(CustomersData, "Customer details");
      });
  }

  ContactUpdatedEvent(customerId: number, index: any) {
    if (customerId === -1) {
      this.adressbookdata.splice(index, 1);
    } else {
      this.reportservice
        .getcustomers(this.userData.id, "", this.userType, "")
        .subscribe((res: any) => {
          const updatedCustomer = res.find(
            (customer) => customer.id === customerId
          );
          this.adressbookdata[index] = updatedCustomer;
        });
    }
  }

  cycleLoadingText() {
    this.loadingInterval = setInterval(() => {
      switch (this.loadingText) {
        case "Fetching call logs":
          this.loadingText = "Fetching call logs.";
          break;
        case "Fetching call logs.":
          this.loadingText = "Fetching call logs..";
          break;
        case "Fetching call logs..":
          this.loadingText = "Fetching call logs...";
          break;
        default:
          this.loadingText = "Fetching call logs";
          break;
      }
    }, 400);
  }

  stopLoadingTextCycle() {
    clearInterval(this.loadingInterval);
  }

  ngOnDestroy() {
    this.stopLoadingTextCycle();
  }
}
