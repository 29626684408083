import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { ReportService } from "../../inbound-report/report.service";
import { NotificationService } from "src/app/notification/notification.service";
import { API_URL } from 'src/global';
import { Subscription } from 'rxjs';
import { LoginService } from "../../login.service";
import * as moment from "moment";


@Component({
  selector: 'app-audiocomp',
  templateUrl: './audiocomp.component.html',
  styleUrls: ['./audiocomp.component.css']
})
export class AudiocompComponent implements OnInit {


  @Input() public id: any = null;
  @Input() public date: any;
  @Input() public callrecord: any;
  @Input() public call_type: any = '';

  @Input() fromDate: string;


  @Input() public daysWithin: number;
  public userData: any;


  public audiocomp: boolean = true;


  api: string = API_URL;

  filteredCallRecords: any[] = [];

  constructor(
    private reportservice: ReportService,
    private notifyService: NotificationService,
    private loginservice: LoginService,



  ) {

  }

  ngOnInit() {
    console.log('AudiocompComponent initialized');
    console.log('fromDate received in AudiocompComponent:', this.fromDate); // Debugging

    this.userData = this.loginservice.getUserdata(false);

    const callRecordSettingType = this.userData.call_record_setting_type;
    const daysWithin = this.userData.days_within;

    // If the setting type is 'all', directly display the audiocomp without any further checks
    if (callRecordSettingType === 'all') {
      this.audiocomp = true;
      return;
    }

    // Check for invalid or missing fromDate
    if (!this.fromDate || isNaN(Date.parse(this.fromDate))) {
      console.error("Invalid or missing fromDate:", this.fromDate);
      this.audiocomp = false; // Handle invalid date
      return;
    }

    const selectedStartDate = new Date(this.fromDate); // User-selected start date
    const today = new Date(); // Current date

    console.log('Parsed Selected Start Date:', selectedStartDate);
    console.log("Today's Date:", today);

    // Check for valid selected start date
    if (isNaN(selectedStartDate.getTime())) {
      console.error("Invalid start date received:", this.fromDate);
      this.audiocomp = false;
      return;
    }

    if (callRecordSettingType === 'days_within' && daysWithin > 0) {
      // Calculate the difference in days between today and the selected start date
      const timeDifference = Math.abs(today.getTime() - selectedStartDate.getTime());
      const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days

      console.log("Day Difference:", dayDifference);

      // Check if the day difference is equal to daysWithin
      if (dayDifference === daysWithin) {
        // Ensure the selected start date matches the provided fromDate
        const formattedSelectedStartDate = selectedStartDate.toISOString().split('T')[0];
        const formattedFromDate = new Date(this.fromDate).toISOString().split('T')[0];

        console.log("Formatted Selected Start Date:", formattedSelectedStartDate);
        console.log("Formatted From Date:", formattedFromDate);

        if (formattedSelectedStartDate === formattedFromDate) {
          this.audiocomp = true; // Show the audio component
        } else {
          this.audiocomp = false; // Hide the component if start date does not match fromDate
        }
      } else {
        this.audiocomp = false; // Hide the component if day difference is not equal to daysWithin
      }
    } else {
      this.audiocomp = false; // Hide the component for invalid daysWithin or other cases
    }
  }

  




  getcallrecord(vffile, date, call_id: any = null, file_type = "") {

    this.reportservice.getcallrecord(vffile, date, call_id, file_type).subscribe(
      (res: any) => {

        let voice_url = this.api + "uploads/downloadedWaveFile/" + vffile.replace(".gsm", ".wav");
        var html_con = '<audio id="audio1" preload controls style="width: 270px; height: 50px; max-width: none; margin: 10px;">' + "  <source src=" + voice_url + " </audio>";
        document.getElementById("audio_" + vffile).innerHTML = html_con;

        this.notifyService.sendNotification(
          "Downloaded",
          "Download Voice successfully",
          "is-success"
        )
      }
    )
  }

  isAudioFileAvailable(url: string) {
    console.log("url of audio file: ", url)
  }

  audioFileErrors: boolean[] = []
  downloadEnabled: boolean[] = []

  handleAudioError(audioFileName: string) {
    console.log(`Audio error for file: ${audioFileName}`);

    this.audioFileErrors[audioFileName] = true
    this.downloadEnabled[audioFileName] = true
  }

  disableVoiceDownload(audioFileName: string) {
    this.downloadEnabled[audioFileName] = false
  }

}


