import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { WhatsappService } from '../whatsapp-services';
import { LoginService } from 'src/app/login.service';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-whatsapp-accounts',
  templateUrl: './whatsapp-accounts.component.html',
  styleUrls: ['./whatsapp-accounts.component.css']
})
export class WhatsappAccountsComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() userEmitter: any = new EventEmitter();
  userInitial: string | null = null;
  userId: string = "";
  dataTable: any;
  userData: any;
  whatsappAccountList: any[] = [];
  whatsappUserList: any[] = [];
  userClearTrigger = false;
  userReadOnly: boolean = false;
  userRequired: boolean = false;



  constructor(
    private whatsappService: WhatsappService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.userData = this.loginService.getUserdata(true);
    this.intitializerUserSettings();
  }

  ngAfterViewInit(): void {
    this.initializeDatatable();
    this.updateDataTable();
  }

  ngOnDestroy(): void {
    this.dataTable.destroy();
  }


  intitializerUserSettings() {
    
    if (this.userData.usergroup != "superadmin") {
      this.userReadOnly = true;
    }
  }

  
  initializeDatatable() {
    this.dataTable = $('#whatsappAccountsTable').DataTable({
      responsive: true,
      lengthChange: false,
      autoWidth: false,
      language: {
        emptyTable: 'No data available'
      },
      buttons: [],
      columns: [
        { data: 'sl_no', title: 'Sl No' },
        { data: 'username', title: 'Whatsapp User' },
        { data: 'wa_number', title: 'Whatsapp Number' },
        { data: 'wb_account_id', title: 'Account ID' },
      ]
    });
  }

  updateDataTable() {

    if (this.userData.usergroup == 'superadmin') {
      const qParams = [
        { key: "scope", value: "superadmin" }
      ]
      this.whatsappService.getWhatsappAccounts(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.whatsappAccountList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
            }));
            this.dataTable.clear();
            this.dataTable.rows.add(this.whatsappAccountList);
            this.dataTable.draw();
          }
        }
      )
    } else {
      const qParams = [
        { key: "scope", value: "customer" },
        { key: "userprofile_id", value: this.userData.id }
      ]
      this.whatsappService.getWhatsappAccounts(qParams).subscribe(
        (res: any) => {
          if (res.status === "1") {
            this.whatsappAccountList = res.data.map((item, index) => ({
              ...item,
              sl_no: index + 1,
            }));
            this.dataTable.clear();
            this.dataTable.rows.add(this.whatsappAccountList);
            this.dataTable.draw();
          }
        }
      )
    }
  }

  onAccountSync() {

    if (this.userId) {
      const data = {
        user_id: this.userId
      }

      this.whatsappService.fetchWhatsappAccounts(data).subscribe((res: any) => {
        if (res.status === "1") {
          this.updateDataTable();
          Swal.fire("Success", "Whatsapp accounts fetched successfully.", "success");
        } else {
          Swal.fire("Something went wrong!", "Failed to fetch accounts.", "error")
        }
      })
    } else {
      this.userRequired = true;
      Swal.fire("Something went wrong!", "Failed to fetch accounts.", "error")
    }

  }

  userEvent(userIds) {
    this.userId = userIds.whatsappUserId
  }
}
