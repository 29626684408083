import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { QueuemanagemetComponent } from './queuemanagemet/queuemanagemet.component';
import { ExtensionComponent } from './extension/extension.component';
import { AddextensionComponent } from './extension/addextension/addextension.component';
import { ExtensionLIstComponent } from './extension/extension-list/extension-list.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { InboundRouteComponent } from './inbound-route/inbound-route.component';
import { TimeconditionComponent } from './inbound-route/timecondition/timecondition.component';
import { InboundRouteService } from './inbound-route/inbound-route.service';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpHeaderResponse } from '@angular/common/http';
import { AddtimeconditionComponent } from './inbound-route/addtimecondition/addtimecondition.component';
import { LoginComponent } from './login/login.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatchPatternListComponent } from './inbound-route/match-pattern-list/match-pattern-list.component';
import { MatchPatternDetailComponent } from './inbound-route/match-pattern-detail/match-pattern-detail.component';
import { FormsModule } from "@angular/forms";
import { InboundRouteListComponent } from './inbound-route/inbound-route-list/inbound-route-list.component';
import { InboundRouteDetailComponent } from './inbound-route/inbound-route-detail/inbound-route-detail.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { VoicebroadcastComponent } from './voicebroadcast/voicebroadcast.component';
import { VoicebroadcastListComponent } from './voicebroadcast/voicebroadcast-list/voicebroadcast-list.component'
import { IvrListComponent } from './ivr/ivr-list/ivr-list.component';
import { IvrDetailComponent } from './ivr/ivr-detail/ivr-detail.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { QueuelistComponent } from './queuemanagemet/queuelist/queuelist.component';
import { AddqueueComponent } from './queuemanagemet/addqueue/addqueue.component';
import { IvrComponent } from './ivr/ivr.component';
import { LevelOneComponent } from './ivr/level-one/level-one.component';
import { LevelTwoComponent } from './ivr/level-two/level-two.component';
import { LevelThreeComponent } from './ivr/level-three/level-three.component';
import { NotificationComponent } from './notification/notification.component';
import { VoiceRecordComponent } from './voice-record/voice-record.component';
import { VoiceComponent } from './voice/voice.component';
import { WeekDaysComponent } from './week-days/week-days.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InboundReportComponent } from './inbound-report/inbound-report.component';
import { InboundReportFilterPipe } from './inbound-report/inbound-report-filter.pipe';
import { UserprofileListComponent } from './usermanagement/usermanagement-list/usermanagement-list.component';
import { UserProfileDetailComponent } from './usermanagement/usermanagement-detail/usermanagement-detail.component';
import { UserPermissionComponent } from './usermanagement/user-permissions/userpermissions.component'
import { UserGroupListComponent } from './usermanagement/subuser-group-list/subuser-group-list.component'
import { UserGroupDetailComponent } from './usermanagement/subuser-group-detail/subuser-group-detail.component';
import { DialerComponent } from './dialer/dialer.component';
import { AddDialerComponent } from './dialer/add-dialer/add-dialer.component';
import { DialerListComponent } from './dialer/dialer-list/dialer-list.component'
import { RightbarComponent } from './rightbar/rightbar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { SubuserGroupComponent } from './usermanagement/user_group_autocomplete/user_group_autocomplete.component'
import { AddDialerProjectComponent } from './dialer/add-dialer-project/add-dialer-project.component';
import { AddDialerExcelComponent } from './dialer/add-dialer-excel/add-dialer-excel.component';
import { SmsComponent } from './sms/sms.component';
import { SmsListComponent } from './sms/sms-list/sms-list.component';
import { AddSmsComponent } from './sms/add-sms/add-sms.component';
import { ViewReportComponent } from './sms/view-report/view-report.component';
import { SmsCountComponent } from './sms/sms-count/sms-count.component';
import { ReportsComponent } from './reports/reports.component';
import { DialerReportComponent } from './dialler-report/dialler-report.component';
import { TemplatesComponent } from './sms/templates/templates.component';
import { SplitPipe } from './pipes/pipes.component';
import { UserGroupEditComponent } from './usermanagement/subuser-group-detail/subuser-group-edit.component'
import { UsersComponent } from './users/users.component';
import { DidmgmtComponent } from './didmgmt/didmgmt.component';
import { ExcelService } from './inbound-report/excel.service';
import { ExcelConvertService } from './sms/view-report/excel-convert.service';
import { CreditManagementComponent } from './credit-management/credit-management.component';
import { AddCreditComponent } from './credit-management/add-credit/add-credit.component';
import { CreditListComponent } from './credit-management/credit-list/credit-list.component';
import { MissedCallReportComponent } from './missed-call-report/missed-call-report.component';
import { InboundFeedbackComponent } from './inbound-feedback/inbound-feedback.component';
import { AddNumbersLogComponent } from './inbound-feedback/add-numbers-log/add-numbers-log.component';
import { ListUsersComponent } from './inbound-feedback/list-users/list-users.component';
import { FeedBackReportComponent } from './inbound-feedback/feed-back-report/feed-back-report.component';
import { AddQuestionsComponent } from './inbound-feedback/add-questions/add-questions.component';
import { QuestionListComponent } from './inbound-feedback/question-list/question-list.component';
import { InboundUserDetailComponent } from './inbound-feedback/inbound-user-detail/inbound-user-detail.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { AllUploadExcelComponent } from './dialer/all-upload-excel/all-upload-excel.component';
import { AddChannelusageComponent } from './credit-management/add-channelusage/add-channelusage.component';
import { ChannelUsageHistoryComponent } from './credit-management/channel-usage-history/channel-usage-history.component';
import { TimeDurationComponent } from './credit-management/time-duration/time-duration.component';
import { AgentUsersComponent } from './usermanagement/agent-users/agent-users.component';
import { AutoCallingManagementComponent } from './auto-calling-management/auto-calling-management.component';
import { AddAutoCallComponent } from './auto-calling-management/add-auto-call/add-auto-call.component';
import { ListAutoCallComponent } from './auto-calling-management/list-auto-call/list-auto-call.component';
import { VbReportComponent } from './vb-report/vb-report.component';
import { VirtualSIpComponent } from './virtual-sip/virtual-sip.component';
import { AddVIrtualSipComponent } from './virtual-sip/add-virtual-sip/add-virtual-sip.component';
import { ListVIrtualsipComponent } from './virtual-sip/list-virtualsip/list-virtualsip.component';
import { IncomingCreditManagementComponent } from './credit-management/incoming-credit-management/incoming-credit-management.component';
import { CreditPlanComponent } from './credit-management/credit-plan/credit-plan.component';
import { AgentReportComponent } from './agent-report/agent-report.component';
import { CallerReportComponent } from './caller-report/caller-report.component';
import { DailpadreportComponent } from './dailpadreport/dailpadreport.component';
import { CreditHistoryComponent } from './credit-management/credit-history/credit-history.component';
import { AutocallReportComponent } from './autocall-report/autocall-report.component';
import { ProgressBarModule } from 'angular-progress-bar';
import { saveAs } from 'file-saver';
import { SmsService } from './sms/sms.service';
import { LivecallreportComponent } from './livecallreport/livecallreport.component';
import { AddSmsCreditComponent } from './credit-management/add-sms-credit/add-sms-credit.component';
import { SmsCreditListComponent } from './credit-management/sms-credit-list/sms-credit-list.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { AddCreditAlertComponent } from './credit-management/add-credit-alert/add-credit-alert.component';
import { ListCreditAlertComponent } from './credit-management/list-credit-alert/list-credit-alert.component';
import { ReportCreditAlertComponent } from './credit-management/report-credit-alert/report-credit-alert.component';
import { SmsCreditHistoryComponent } from './credit-management/sms-credit-history/sms-credit-history.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ElasticReportComponent } from './elastic-report/elastic-report.component';
// import { AddCampgainComponent } from './callcentreadmin/add-campgain/add-campgain.component';


// ==============================CONTACT CENTER CAMPAGIN COMPONENTS START========================
import { AddUserCreditsComponent } from './credit-management/add-user-credits/add-user-credits.component';
import { UserCreditListComponent } from './credit-management/user-credit-list/user-credit-list.component';
import { UserCreditHistoryComponent } from './credit-management/user-credit-history/user-credit-history.component';
import { CreditAlertReportComponent } from './credit-management/credit-alert-report/credit-alert-report.component';
import { AddressbookComponent } from './addressbook/addressbook.component';
import { CustomercontactComponent } from './addressbook/customercontact/customercontact.component';
import { CustomerlogComponent } from './addressbook/customerlog/customerlog.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { AddressContactComponent } from './address-contact/address-contact.component';
import { CommentsComponent } from './Components/comments/comments.component';

import { CallCountReportComponent } from './call-count-report/call-count-report.component';
import { VoicemailComponent } from './voicemail/voicemail-old/voicemail.component';
import { VoicemailTemplateComponent } from './voicemail/voicemail-template/voicemail-template.component';
import { TemplateListComponent } from './voicemail/template-list/template-list.component';
import { TokenInterceptorService } from './interceptor.service';

import { VirtualsipReportComponent } from './virtualsip-report/virtualsip-report.component';
import { CallSummaryComponent } from './Components/call-summary/call-summary.component';
import { FilterDetailsComponent } from './Components/filter-details/filter-details.component';
import { CallTransferCreateTemplateComponent } from './call-transfer/call-transfer-create-template/call-transfer-create-template.component';
import { CallTransferTemplatesListComponent } from './call-transfer/call-transfer-templates-list/call-transfer-templates-list.component'
import { RenewalHistoryComponent } from './usermanagement/renewal-history/renewal-history.component';
import { CallbackReportComponent } from './callback-report/callback-report.component';
import { ProductDetailsComponent } from './usermanagement/product-details/product-details.component';
import { AddproductToclientComponent } from './usermanagement/addproduct-toclient/addproduct-toclient.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component'
import { SendemailComponent } from './usermanagement/sendemail/sendemail.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AudioReportComponent } from './audio-report/audio-report.component';
import { NewTableContentComponent } from './new-table-content/new-table-content.component'
import { CustomerComponent } from './Components/customer/customer.component'
import { ZohoIntegrationComponent } from './zoho-integration/zoho-integration.component'
import { SuperadminAutocallComponent } from './superadmin-autocall/superadmin-autocall.component'
import { CustomFieldComponent } from './addressbook/custom-field/custom-field.component';
import { FollowUpComponent } from './Components/follow-up/follow-up.component';
import { FollowUpReportComponent } from './Components/follow-up-report/follow-up-report.component';
import { AddressBookFilterComponent } from './addressbook/address-book-filter/address-book-filter.component';
import { AudiocompComponent } from './Components/audiocomp/audiocomp.component';
import { LeadsquaredIntegrationComponent } from './leadsquared-integration/leadsquared-integration.component'
import { ParentTagIconComponent } from './report-settings/parent-tag-icon/parent-tag-icon.component';
import { ChildTagIconComponent } from './report-settings/child-tag-icon/child-tag-icon.component';
import { KylasIntegrationComponent } from './kylas-integration/kylas-integration.component';
import { CallerRegistrationComponent } from './inbound-route/caller-registration/caller-registration.component';
import { AddPushnotificationComponent } from './add-pushnotification/add-pushnotification.component';
import { PushnotificationHistoryComponent } from './pushnotification-history/pushnotification-history.component'

import { FirebaseAppConfig } from '@angular/fire';

import { AngularFireMessagingModule } from '@angular/fire/messaging'
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { CombinedUsersComponent } from './usermanagement/combined-users/combined-users.component';
import { IntegrationConfigComponent } from './integrationconfig/integrationconfig.component';
import { WhatsappConfigurationComponent } from './whatsapp/whatsapp-configuration/whatsapp-configuration.component';
import { WhatsappTemplatesComponent } from './whatsapp/whatsapp-templates/whatsapp-templates.component';
import { WhatsappPlaceholdersComponent } from './whatsapp/whatsapp-placeholders/whatsapp-placeholders.component';
import { WhatsappAccountsComponent } from './whatsapp/whatsapp-accounts/whatsapp-accounts.component';
import { WhatsappUserComponent } from './whatsapp/whatsapp-user/whatsapp-user.component';
import { WhatsappReportComponent } from './whatsapp/whatsapp-report/whatsapp-report.component';
import { IvrTemplatesComponent } from './sms/ivr-templates/ivr-templates.component';
import { WhatsappSettingsComponent } from './whatsapp/whatsapp-settings/whatsapp-settings.component'
import { SendatatoexternalapilogReportComponent } from './sendatatoexternalapilog-report/sendatatoexternalapilog-report.component'
import { AddMerittoagentsComponent } from './add-merittoagents/add-merittoagents.component'
import { BreakTimeReportComponent } from './break-time-report/break-time-report.component'



// import { NgMultiSelectDropDownModule } from 'angular7-dropdown';

export const MY_MOMENT_FORMATS = {
	parseInput: 'DD-MM-YYYY',
	fullPickerInput: 'DD-MM-YYYY',
	datePickerInput: 'DD-MM-YYYY',
	timePickerInput: 'HH:mm:ss',
	monthYearLabel: 'MMMM YYYY',
	dateA11yLabel: 'DD-MM-YYYY',
	monthYearA11yLabel: 'MMMM YYYY',
};


@NgModule({
	declarations: [
		AppComponent,
		SidebarComponent,
		QueuemanagemetComponent,
		ExtensionComponent,
		AddextensionComponent,
		ExtensionLIstComponent,
		UsermanagementComponent,
		InboundRouteComponent,
		TimeconditionComponent,
		AddtimeconditionComponent,
		MatchPatternListComponent,
		MatchPatternDetailComponent,
		LoginComponent,
		InboundRouteListComponent,
		InboundRouteDetailComponent,
		FileuploadComponent,
		VoicebroadcastComponent,
		VoicebroadcastListComponent,
		SubmenuComponent,
		QueuelistComponent,
		AddqueueComponent,
		IvrComponent,
		IvrListComponent,
		IvrDetailComponent,
		LevelOneComponent,
		LevelTwoComponent,
		LevelThreeComponent,
		NotificationComponent,
		VoiceRecordComponent,
		VoiceComponent,
		WeekDaysComponent,
		UserProfileComponent,
		InboundReportComponent,
		UserprofileListComponent,
		UserProfileDetailComponent,
		UserPermissionComponent,
		UserGroupListComponent,
		UserGroupDetailComponent,
		RightbarComponent,
		DashboardComponent,
		PageNotFoundComponent,
		SubuserGroupComponent,
		DialerComponent,
		AddDialerComponent,
		DialerListComponent,
		AddDialerProjectComponent,
		AddDialerExcelComponent,
		SmsComponent,
		SmsListComponent,
		AddSmsComponent,
		ViewReportComponent,
		ReportsComponent,
		DialerReportComponent,
		TemplatesComponent,
		SplitPipe,
		TemplatesComponent,
		DidmgmtComponent,
		CreditManagementComponent,
		AddCreditComponent,
		CreditListComponent,
		UserGroupEditComponent,
		UsersComponent,
		InboundReportFilterPipe,
		MissedCallReportComponent,
		InboundFeedbackComponent,
		AddNumbersLogComponent,
		ListUsersComponent,
		FeedBackReportComponent,
		AddQuestionsComponent,
		QuestionListComponent,
		InboundUserDetailComponent,
		AllUploadExcelComponent,
		AddChannelusageComponent,
		ChannelUsageHistoryComponent,
		TimeDurationComponent,
		AgentUsersComponent,
		AutoCallingManagementComponent,
		AddAutoCallComponent,
		ListAutoCallComponent,
		VbReportComponent,
		VirtualSIpComponent,
		AddVIrtualSipComponent,
		ListVIrtualsipComponent,
		IncomingCreditManagementComponent,
		CreditPlanComponent,
		AgentReportComponent,
		CallerReportComponent,
		DailpadreportComponent,
		CreditHistoryComponent,
		AutocallReportComponent,
		SmsCountComponent,
		LivecallreportComponent,
		AddSmsCreditComponent,
		SmsCreditListComponent,
		ImpersonateComponent,
		AddCreditAlertComponent,
		ListCreditAlertComponent,
		ReportCreditAlertComponent,
		SmsCreditHistoryComponent,
		// ===============================DECLARATIONS OF CONTACT CENTRE CUSTOMER TAB==================

		AddUserCreditsComponent,
		UserCreditListComponent,
		UserCreditHistoryComponent,
		CreditAlertReportComponent,
		ElasticReportComponent,
		AddressbookComponent,
		CustomercontactComponent,
		CustomerlogComponent,
		SvgIconComponent,
		AddressContactComponent,
		CommentsComponent,
		CallCountReportComponent,
		VoicemailComponent,
		VoicemailTemplateComponent,
		TemplateListComponent,
		VirtualsipReportComponent,
		CallSummaryComponent,
		FilterDetailsComponent,
		RenewalHistoryComponent,
		CallbackReportComponent,
		CallTransferCreateTemplateComponent,
		CallTransferTemplatesListComponent,
		ProductDetailsComponent,
		AddproductToclientComponent,
		ForgotPasswordComponent,
		VerifyEmailComponent,
		SendemailComponent,
		ResetPasswordComponent,
		AudioReportComponent,
		NewTableContentComponent,
		SuperadminAutocallComponent,
		CustomerComponent,
		ZohoIntegrationComponent,
		AudiocompComponent,
		FollowUpComponent,
		FollowUpReportComponent,
		CustomFieldComponent,
		AddressBookFilterComponent,
		LeadsquaredIntegrationComponent,
		ParentTagIconComponent,
		ChildTagIconComponent,
		KylasIntegrationComponent,
		CallerRegistrationComponent,
		AddPushnotificationComponent,
		PushnotificationHistoryComponent,
		CombinedUsersComponent,
		IntegrationConfigComponent,
		IvrTemplatesComponent,
		WhatsappConfigurationComponent,
		WhatsappTemplatesComponent,
		WhatsappPlaceholdersComponent,
		WhatsappAccountsComponent,
		WhatsappUserComponent,
		WhatsappReportComponent,
		SendatatoexternalapilogReportComponent,
		IvrTemplatesComponent,
		WhatsappSettingsComponent,
		AddMerittoagentsComponent,
		BreakTimeReportComponent,



	],
	imports: [
		BrowserModule,
		FormsModule,
		Ng4LoadingSpinnerModule.forRoot(),
		BrowserAnimationsModule,
		AppRoutingModule,
		HttpClientModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		OwlMomentDateTimeModule,
		ReactiveFormsModule,
		NgxMaterialTimepickerModule,
		AutocompleteLibModule,
		ProgressBarModule,
		NgMultiSelectDropDownModule.forRoot(),
		AngularMultiSelectModule,
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAuthModule,
		AngularFireDatabaseModule,
		AngularFireMessagingModule

	],
	providers: [
		InboundRouteService,
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
		ExcelService,
		SmsService,
		ExcelConvertService,
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
