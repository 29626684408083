import { MY_MOMENT_FORMATS } from './../app.module';
import { Component, OnInit } from "@angular/core";
import { LoginService } from "../login.service";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { distinctUntilChanged } from "rxjs/operators";
import { distinct } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { IntegrationService } from './integrationconfig.service';

@Component({
  selector: "app-integrationconfig",
  templateUrl: "./integrationconfig.component.html",
  styleUrls: ["./integrationconfig.component.css"],
})
export class IntegrationConfigComponent implements OnInit {
  keyword = "name";
  public FormatForm: FormGroup;
  users: any = new Array();
  formatuserid: any;
  userData: any;
  formatLabel: string;
  Formatinitial: string = "";
  UserProfileId: number = 0;
  Formats: number = 0;
  status: string;
  data: any[];
  integrationArray: any[] = [];
  formatList: any[] = [];
  fields: any[];
  selectedFormat: any;
  fieldsToDisplay: any[] = [];


  formatFields: { [key: string]: string[] } = {};
  relatedFields: string[] = [];

  savedFormValues: { [key: string]: any } = {}; // Store form values for each format
  integrationIdToEdit: number | null = null; // Add a property to store the ID of the integration being edited

  usrgrp: any;

  constructor(
    private loginService: LoginService,
    private loginservice: LoginService,

    private activeroute: ActivatedRoute,
    private fb: FormBuilder,
    private integrationService: IntegrationService,
    private http: HttpClient
  ) {
    this.FormatForm = this.fb.group({
      name: [''],
      user: [''],
      url: [''],
      format: [''],
      auth_code: [''],
      api_cred: [''],
      autocall: [''],
      autocall_did: [''],
      autocall_channel_id: [''],
      liveCallPopUp: [''],
      number: [''],
      kylassourceid: [''],
      liveCallPopup: [false],
      LiveCallPopUpUrl: [''],
      kylas_user_id: [''],
      kylas_api_key: [''],
      custom_data: [''],
      custom_response: [false],
      default_email: [''],
      default_destination: [''],
      default_mobile_number: [''],
      kylas_source_id: [''],
      custom_headers: [''],
      custom_query_params: ['']
    });
  }

  ngOnInit() {

    this.initForm();

    console.log("iiiiiiiiiiiiii");
    this.userData = this.loginservice.getUserdata(true);

    this.UserProfileId = this.userData.id;
    
    this.usrgrp = this.userData.usergroup

    this.integrationService
      .getFormatListtable(this.UserProfileId)
      .subscribe((data: any) => {
        this.integrationArray = data["data"];
        console.log("data", this.integrationArray);
      });

    this.formatuserid = [];


    this.loadFormats();
    this.loadIntegrations(); // Load integrations on component initialization


  }



  loadFormats(): void {
    this.integrationService.getFormatList().subscribe((response: any) => {
      if (response.status === '1') {
        this.formatList = response.data;
        console.log('Format list:', this.formatList); // Check formatList here
      } else {
        console.error('Failed to load format list:', response.error);
      }
    });
  }

  loadIntegrations(): void {
    this.integrationService.integrationdata().subscribe((res: any) => {
      if (res.status === '1') {
        this.integrationArray = res.data;
      } else {
        Swal.fire('Error', 'Failed to load integrations', 'error');
      }
    });
  }


  initForm(): void {
    this.FormatForm = this.fb.group({});
  }


  selectFormat(event: any): void {
    const selectedFormat = event.target.value;

    // Find the format details
    const formatInfo = this.formatList.find(format => format.format === selectedFormat);
    if (formatInfo) {
      // Extract fields and their values to display
      this.fieldsToDisplay = Object.keys(formatInfo).map(key => ({ key, value: formatInfo[key] }));

      // Clear previous form controls and add new ones
      this.clearFormControls();
      this.createFormControls();
    }
  }

  clearFormControls(): void {
    Object.keys(this.FormatForm.controls).forEach(controlName => {
      if (controlName !== 'format') {
        this.FormatForm.removeControl(controlName);
      }
    });
  }



  createFormControls(): void {
    this.fieldsToDisplay.forEach(field => {
      this.FormatForm.addControl(field.key, new FormControl(field.value));
    });
  }



  // onSubmit(): void {
  //   if (this.FormatForm.valid) {
  //     const data = this.FormatForm.value;
  //     console.log('Form data:', data);

  //     this.integrationService.addintegration(data).subscribe((res: any) => {
  //       if (res.status === '1') {
  //         Swal.fire('Success', res.message, 'success');
  //         if (res.message === 'Added successfully.') {
  //           this.FormatForm.reset(); // Only reset the form if a new record is added
  //         } else if (res.message === 'Updated successfully.') {
  //           this.populateForm(data); // Repopulate the form with updated data
  //         }
  //       } else {
  //         Swal.fire('Error', res.error || 'Something went wrong', 'error');
  //       }
  //     });
  //   }
  // }


  // onSubmit(): void {
  //   if (this.FormatForm.valid) {
  //     const data = this.FormatForm.value;
  //     console.log('Form data:', data);

  //     if (this.integrationIdToEdit) {
  //       // If editing an existing integration
  //       this.integrationService.updateIntegration(this.integrationIdToEdit, data).subscribe((res: any) => {
  //         if (res.status === '1') {
  //           Swal.fire('Success', res.message, 'success');
  //           this.loadIntegrations();
  //         } else {
  //           Swal.fire('Error', res.error || 'Something went wrong', 'error');
  //         }
  //       });
  //     } else {
  //       // If adding a new integration
  //       this.integrationService.addintegration(data).subscribe((res: any) => {
  //         if (res.status === '1') {
  //           Swal.fire('Success', res.message, 'success');
  //           this.loadIntegrations();
  //           if (res.message === 'Added successfully.') {
  //             this.FormatForm.reset(); // Only reset the form if a new record is added
  //           } else if (res.message === 'Updated successfully.') {
  //             this.populateForm(data); // Repopulate the form with updated data
  //           }
  //         } else {
  //           Swal.fire('Error', res.error || 'Something went wrong', 'error');
  //         }
  //       });
  //     }
  //   }
  // }


  onSubmit(): void {
    if (this.FormatForm.valid) {
      const data = { ...this.FormatForm.value }; // Make a copy of the form data
      console.log('Form data:', data);

      if (this.integrationIdToEdit) {
        // If editing an existing integration
        delete data.name; // Remove the name field from the data before updating

        this.integrationService.updateIntegration(this.integrationIdToEdit, data).subscribe((res: any) => {
          if (res.status === '1') {
            Swal.fire('Success', res.message, 'success');
            this.loadIntegrations();
          } else {
            Swal.fire('Error', res.error || 'Something went wrong', 'error');
          }
        });
      } else {
        // If adding a new integration
        this.integrationService.addintegration(data).subscribe((res: any) => {
          if (res.status === '1') {
            Swal.fire('Success', res.message, 'success');
            this.loadIntegrations();
            if (res.message === 'Added successfully.') {
              this.FormatForm.reset(); // Only reset the form if a new record is added
            } else if (res.message === 'Updated successfully.') {
              this.populateForm(data); // Repopulate the form with updated data
            }
          } else {
            Swal.fire('Error', res.error || 'Something went wrong', 'error');
          }
        });
      }
    }
  }



  populateForm(data: any): void {
    Object.keys(data).forEach(key => {
      if (this.FormatForm.controls[key]) {
        this.FormatForm.controls[key].setValue(data[key]);
      } else {
        this.FormatForm.addControl(key, new FormControl(data[key]));
      }
    });
  }


  oneditIntegration(id: number): void {
    this.integrationIdToEdit = id; // Store the ID of the integration being edited
    this.integrationService.getIntegrationById(id).subscribe((res: any) => {
      if (res.status === '1') {
        const data = res.data;
        this.populateForm(data);
        this.selectedFormat = data.format;
        this.selectFormat({ target: { value: this.selectedFormat } }); // Trigger format selection to load fields
      } else {
        Swal.fire('Error', res.error || 'Something went wrong', 'error');
      }
    });
  }








  ondeleteIntegration(id: number) {
    Swal.fire({
      title: "Are you sure?",
      text: "The integration will be deleted.",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it.",
      cancelButtonText: "No, don't delete."
    }).then((result) => {
      if (!result.dismiss) {  // Check if the action was confirmed
        console.log(`Attempting to delete integration with id: ${id}`); // Debugging log
        this.integrationService.deleteIntegration(id).subscribe(
          (res: any) => {
            console.log("Response from delete request:", res); // Debugging log
            if (res.status === "1") {
              Swal.fire("Success", res.message, "success");
              this.loadIntegrations();
            } else {
              Swal.fire("Error", res.error, "error");
            }
          },
          (error) => {
            console.error("Error from delete request:", error); // Detailed error log
            Swal.fire("Error", "There was an error deleting the integration.", "error");
          }
        );
      }
    });
  }





  onChangeSearchformat(val: string) {

  }


}

