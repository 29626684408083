import { Component, OnInit } from '@angular/core';
import { UsermanagementService } from "../../usermanagement.service";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-add-credit-alert',
  templateUrl: './add-credit-alert.component.html',
  styleUrls: ['./add-credit-alert.component.css']
})
export class AddCreditAlertComponent implements OnInit {
  companyList: string | undefined;
  companies: any;
  DidList: string | undefined;
  Dids: any;
  didnumbers: any;
  planName: any;
  disableSelect = true;
  alertLimit: any;
  ids: any;
  Alertion: any;
  smsType: any;
  Route: boolean;
  alertLimitType: boolean;
  CreditLimitType: any;
  didList = [["1", "2", "3"]]

  constructor(
    private usermanagementservice: UsermanagementService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.alertLimitType = false;
    this.Route = false;
    const did = document.getElementById('DID');
    this.route.params.subscribe(params => {
      this.ids = params['id'];
      this.Alertion = params['alertion'];
      this.Dids = params['did']
      console.log(this.ids, this.Alertion, this.Dids)
      if (this.ids == 'new') {
        this.loadCompany()
      }
      else {
        this.loadDID()
      }
    });
  }

  loadCompany() {
    this.usermanagementservice.CustomerComapnyList().subscribe(
      (data: any) => {
        this.companies = data
      }
    )
  }

  loadDID() {
    var datas = {
      'user': this.ids,
      'alertion': this.Alertion,
      'did': this.Dids
    }
    this.usermanagementservice.listAlertType(datas).subscribe(
      (data: any) => {
        console.log(data['company'], data['did'], data['alertion'], data['alert_limit'], data["credit_limit_type"], data['alert_type'])
        this.companyList = data['company']
        this.DidList = data['did']
        this.Alertion = data['alertion']
        this.CreditLimitType = data['credit_limit_type']
        this.smsType = data['alert_type']
        if (data['alert_limit'] == "%") {
          this.alertLimit = "0"
        }
        else {
          this.alertLimit = data['alert_limit']
        }
      }
    )
  }

  getcompanycredit() {
    this.usermanagementservice.getExistingDidNumber(this.companyList).subscribe(
      (data: any) => {
        this.Dids = data.data
      }
    )
  }

  AddAlert() {
    console.log(this.companyList, this.DidList, this.Alertion, this.alertLimit, this.smsType)
    if (this.companyList == undefined || this.Alertion == undefined || this.alertLimit == undefined || this.smsType == undefined) {
      Swal.fire('Oops..!', 'please fill details', 'warning')
    }
    else {
      var data = {
        'company': this.companyList,
        'did': this.DidList,
        'alertion': this.Alertion,
        'smslimit': this.alertLimit,
        'credit_limit_type': this.CreditLimitType,
        'smstype': this.smsType,
        'status': "Success"
      }
      this.usermanagementservice.addAlertDid(data).subscribe(
        (data: any) => {
          if (data['status'] == 1) {
            Swal.fire('Created', 'Created Plan', 'success')
            this.companyList = '';
            this.DidList = '',
              this.Alertion = '';
            this.alertLimit = '';
            this.CreditLimitType = '';
            this.smsType
          }
          else {
            Swal.fire('Oops..!', data['message'], 'warning')
          }

        }
      )
    }

  }

  UpdateAlertType() {
    if (this.companyList == undefined || this.Alertion == undefined || this.alertLimit == undefined || this.smsType == undefined) {
      Swal.fire('Oops..!', 'please fill details', 'warning')
    }
    else {
      var data = {
        'user': this.ids,
        'company': this.companyList,
        'did': this.DidList,
        'alertion': this.Alertion,
        'alert_limit': this.alertLimit,
        'credit_limit_type': this.CreditLimitType,
        'alert_type': this.smsType
      }
      this.usermanagementservice.updateAlertType(data).subscribe(
        (data: any) => {
          if (data['status'] == 1) {
            Swal.fire('Updated', 'Plan Updated', 'success')
          }
          else {
            Swal.fire('Oops..!', data['message'], 'warning')
          }

        }
      )
    }

  }

  AlertionRoute() {
    if (this.Alertion == "Route") {
      this.Route = true;
    }
    else {
      this.Route = false;
    }
  }

  AlertLimitType() {
    if (this.CreditLimitType == "Custom") {
      this.alertLimitType = true;
    }
    else {
      this.alertLimitType = false;
      this.alertLimit = "";
    }
  }
}
