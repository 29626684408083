import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FollowUpService } from './follow-up.service'
import { NotificationService } from "src/app/notification/notification.service"
import Swal from 'sweetalert2'
import { LoginService } from 'src/app/login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-follow-up',
  templateUrl: './follow-up.component.html',
  styleUrls: ['./follow-up.component.css'],
})
export class FollowUpComponent implements OnInit {
  highlightedFollowUpId: number | null = null;

  @Input() public customer_id: any
  @Output() followUpUpdated = new EventEmitter<number>();

  followUpPopup: boolean = false
  activeTab: 'addFollowUp' | 'followUpList' = 'addFollowUp'
  followUpDate: string = ''
  followUpDescription: string = ''
  followUpStatus: string = 'pending'
  followUpList: any
  editMode: boolean = false
  follow_up_id: any
  userData: any
  close_popup: boolean = false;
  isFollowUpListLoaded: boolean = false;
  initialized: boolean = false;

  constructor(
    private followupservice: FollowUpService,
    private notifyservice: NotificationService,
    private router: Router,
    private loginservice: LoginService,
    private route: ActivatedRoute,
  ) { }


  ngOnInit() {
    console.log('------inside follow-up component-----')
    this.userData = this.loginservice.getUserdata(true);

    this.route.params.subscribe(params => {
      const customerId = params['customerId'];
      const followUpId = params['followUpId'];
      console.log('customer', customerId)
      if (customerId != undefined) {
        this.followUpPopup = true;
        this.editMode = false
        this.activeTab = "followUpList";
        this.customer_id = customerId;
        this.close_popup = true;
        this.loadFollowUpList(followUpId ? +followUpId : null);
      }

    });
  }
  loadFollowUpList(followUpId?: any) {
    if (this.activeTab === 'followUpList') {
      this.followupservice.getFollowUp(this.customer_id).subscribe(
        (data: any) => {
          this.followUpList = data;
          if (followUpId !== undefined) {
            this.highlightedFollowUpId = followUpId;
          }
        }
      );
    }
  }

  isHighlighted(followUp: any): boolean {
    return this.highlightedFollowUpId === followUp.id;
  }

  openFollowUpPopup(customer_id) {
    this.followUpPopup = true
    this.editMode = false
    this.activeTab = 'addFollowUp'
    this.customer_id = customer_id
  }

  closeFollowUpPopup() {
    console.log('close pop up in follow up component')
    this.followUpPopup = false
    this.clearFormFields()
    if (this.close_popup) {
      this.router.navigate(['/home2']);
    }

  }

  submitFollowUp() {
    const followUpData: any = {
      customer_id: this.customer_id,
      follow_up_date: this.followUpDate,
      description: this.followUpDescription,
      status: this.followUpStatus
    }

    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      followUpData.created_user = {
        number: this.userData.phone,
        name: this.userData.name,
        user_type: this.userData.usertype
      }
    }

    this.followupservice.AddFollowUp(followUpData).subscribe(
      (res: any) => {
        this.notifyservice.sendNotification(
          "Success",
          "Follow up details created",
          "is-success"
        )
        this.clearFormFields()
        this.closeFollowUpPopup()
      }
    )
  }

  switchTab(tab) {
    this.activeTab = tab;
    if (tab === 'followUpList' && !this.isFollowUpListLoaded) {
      this.editMode = false;
      this.loadFollowUpList();
    } else {
      this.clearFormFields();
      this.editMode = false;
    }
  }


  deleteFollowUp(follow_up_id: any) {
    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete this follow up detail?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
    }).then(result => {
      if (result.value) {
        this.followupservice.DeleteFollowUp(follow_up_id).subscribe(
          (res: any) => {
            if (res.status === '1') {
              this.notifyservice.sendNotification("Deleted", "Follow up details deleted successfully", "is-danger")
              this.loadFollowUpList()
            }
          }
        )
      }
    })
  }

  clearFormFields() {
    this.followUpDate = ''
    this.followUpDescription = ''
    this.followUpStatus = 'pending'
  }

  editFollowUp(followUp: any) {
    this.follow_up_id = followUp.id
    this.followUpDate = followUp.follow_up_date
    this.followUpDescription = followUp.description
    this.followUpStatus = followUp.status

    this.editMode = true
  }

  updateFollowUp() {
    const followUpData: any = {
      customer_id: this.customer_id,
      follow_up_date: this.followUpDate,
      description: this.followUpDescription,
      status: this.followUpStatus
    }

    if (this.userData.usertype == 'agentUser' || this.userData.usertype == 'dialerUser') {
      followUpData.created_user = {
        number: this.userData.phone,
        name: this.userData.name,
        user_type: this.userData.usertype
      }
    }

    this.followupservice.EditFollowUp(this.follow_up_id, followUpData).subscribe(
      (res: any) => {
        if (res.status === '1') {
          this.notifyservice.sendNotification("Updated", "Follow up details updated successfully", "is-info")
          this.editMode = false
          this.clearFormFields()
          this.followUpUpdated.emit(this.follow_up_id)
          this.switchTab('followUpList')
        }
      }
    )
  }

  goBackToList() {
    this.editMode = false
    this.clearFormFields()
  }

}


