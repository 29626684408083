import { SendemailComponent } from "./usermanagement/sendemail/sendemail.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { CreditPlanComponent } from "./credit-management/credit-plan/credit-plan.component";
import { AddextensionComponent } from "./extension/addextension/addextension.component";
import { ExtensionComponent } from "./extension/extension.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QueuemanagemetComponent } from "./queuemanagemet/queuemanagemet.component";
import { ExtensionLIstComponent } from "./extension/extension-list/extension-list.component";
import { InboundRouteComponent } from "./inbound-route/inbound-route.component";
import { TimeconditionComponent } from "./inbound-route/timecondition/timecondition.component";
import { AddtimeconditionComponent } from "./inbound-route/addtimecondition/addtimecondition.component";
import { UsermanagementComponent } from "./usermanagement/usermanagement.component";
import { MatchPatternListComponent } from "./inbound-route/match-pattern-list/match-pattern-list.component";
import { MatchPatternDetailComponent } from "./inbound-route/match-pattern-detail/match-pattern-detail.component";
import { InboundRouteListComponent } from "./inbound-route/inbound-route-list/inbound-route-list.component";
import { InboundRouteDetailComponent } from "./inbound-route/inbound-route-detail/inbound-route-detail.component";
import { VoicebroadcastComponent } from "./voicebroadcast/voicebroadcast.component";
import { VoicebroadcastListComponent } from "./voicebroadcast/voicebroadcast-list/voicebroadcast-list.component";
import { IvrListComponent } from "./ivr/ivr-list/ivr-list.component";
import { IvrDetailComponent } from "./ivr/ivr-detail/ivr-detail.component";
import { QueuelistComponent } from "./queuemanagemet/queuelist/queuelist.component";
import { AddqueueComponent } from "./queuemanagemet/addqueue/addqueue.component";
import { IvrComponent } from "./ivr/ivr.component";
import { VoiceRecordComponent } from "./voice-record/voice-record.component";
import { InboundReportComponent } from "./inbound-report/inbound-report.component";
import { UserprofileListComponent } from "./usermanagement/usermanagement-list/usermanagement-list.component";
import { UserProfileDetailComponent } from "./usermanagement/usermanagement-detail/usermanagement-detail.component";
import { UserPermissionComponent } from "./usermanagement/user-permissions/userpermissions.component";
import { UserGroupListComponent } from "./usermanagement/subuser-group-list/subuser-group-list.component";
import { UserGroupDetailComponent } from "./usermanagement/subuser-group-detail/subuser-group-detail.component";
import { DialerComponent } from "./dialer/dialer.component";
import { AddDialerComponent } from "./dialer/add-dialer/add-dialer.component";
import { DialerListComponent } from "./dialer/dialer-list/dialer-list.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AppComponent } from "./app.component";
import { AddDialerProjectComponent } from "./dialer/add-dialer-project/add-dialer-project.component";
import { AddDialerExcelComponent } from "./dialer/add-dialer-excel/add-dialer-excel.component";
import { AllUploadExcelComponent } from "./dialer/all-upload-excel/all-upload-excel.component";

import { SmsComponent } from "./sms/sms.component";
import { SmsListComponent } from "./sms/sms-list/sms-list.component";
import { ViewReportComponent } from "./sms/view-report/view-report.component";
import { SmsCountComponent } from "./sms/sms-count/sms-count.component";
import { AddSmsComponent } from "./sms/add-sms/add-sms.component";
import { ReportsComponent } from "./reports/reports.component";
import { DialerReportComponent } from "./dialler-report/dialler-report.component";
import { TemplatesComponent } from "./sms/templates/templates.component";
import { IvrTemplatesComponent } from "./sms/ivr-templates/ivr-templates.component";
import { UserGroupEditComponent } from "./usermanagement/subuser-group-detail/subuser-group-edit.component";

import { UsersComponent } from "./users/users.component";
import { DidmgmtComponent } from "./didmgmt/didmgmt.component";
import { CreditManagementComponent } from "./credit-management/credit-management.component";
import { AddCreditComponent } from "./credit-management/add-credit/add-credit.component";
import { AddSmsCreditComponent } from "./credit-management/add-sms-credit/add-sms-credit.component";
import { SmsCreditListComponent } from "./credit-management/sms-credit-list/sms-credit-list.component";
import { CreditListComponent } from "./credit-management/credit-list/credit-list.component";
import { IncomingCreditManagementComponent } from "./credit-management/incoming-credit-management/incoming-credit-management.component";
import { AddChannelusageComponent } from "./credit-management/add-channelusage/add-channelusage.component";
import { ChannelUsageHistoryComponent } from "./credit-management/channel-usage-history/channel-usage-history.component";
import { TimeDurationComponent } from "./credit-management/time-duration/time-duration.component";

import { MissedCallReportComponent } from "./missed-call-report/missed-call-report.component";
import { VbReportComponent } from "./vb-report/vb-report.component";

import { InboundFeedbackComponent } from "./inbound-feedback/inbound-feedback.component";
import { AddNumbersLogComponent } from "./inbound-feedback/add-numbers-log/add-numbers-log.component";
import { FeedBackReportComponent } from "./inbound-feedback/feed-back-report/feed-back-report.component";
import { ListUsersComponent } from "./inbound-feedback/list-users/list-users.component";
import { AddQuestionsComponent } from "./inbound-feedback/add-questions/add-questions.component";
import { QuestionListComponent } from "./inbound-feedback/question-list/question-list.component";
import { InboundUserDetailComponent } from "./inbound-feedback/inbound-user-detail/inbound-user-detail.component";
import { AgentUsersComponent } from "./usermanagement/agent-users/agent-users.component";
import { AutoCallingManagementComponent } from "./auto-calling-management/auto-calling-management.component";
import { AddAutoCallComponent } from "./auto-calling-management/add-auto-call/add-auto-call.component";
import { ListAutoCallComponent } from "./auto-calling-management/list-auto-call/list-auto-call.component";
import { ListVIrtualsipComponent } from "./virtual-sip/list-virtualsip/list-virtualsip.component";
import { AddVIrtualSipComponent } from "./virtual-sip/add-virtual-sip/add-virtual-sip.component";
import { VirtualSIpComponent } from "./virtual-sip/virtual-sip.component";
import { AgentReportComponent } from "./agent-report/agent-report.component";
import { CallerReportComponent } from "./caller-report/caller-report.component";
import { DailpadreportComponent } from "./dailpadreport/dailpadreport.component";
import { CreditHistoryComponent } from "././credit-management/credit-history/credit-history.component";
import { AutocallReportComponent } from "./autocall-report/autocall-report.component";
import { LivecallreportComponent } from "./livecallreport/livecallreport.component";
import { ImpersonateComponent } from "./impersonate/impersonate.component";
import { LoginComponent } from "./login/login.component";
import { AddCreditAlertComponent } from "./credit-management/add-credit-alert/add-credit-alert.component";
import { ListCreditAlertComponent } from "./credit-management/list-credit-alert/list-credit-alert.component";
import { ReportCreditAlertComponent } from "./credit-management/report-credit-alert/report-credit-alert.component";
import { SmsCreditHistoryComponent } from "./credit-management/sms-credit-history/sms-credit-history.component";
import { AddUserCreditsComponent } from "./credit-management/add-user-credits/add-user-credits.component";
import { UserCreditListComponent } from "./credit-management/user-credit-list/user-credit-list.component";
import { UserCreditHistoryComponent } from "./credit-management/user-credit-history/user-credit-history.component";
import { CreditAlertReportComponent } from "./credit-management/credit-alert-report/credit-alert-report.component";
import { ElasticReportComponent } from "./elastic-report/elastic-report.component";
import { CustomerlogComponent } from "./addressbook/customerlog/customerlog.component";
import { CustomercontactComponent } from "./addressbook/customercontact/customercontact.component";
import { AddressbookComponent } from "./addressbook/addressbook.component";
import { CallCountReportComponent } from "./call-count-report/call-count-report.component";
import { VirtualsipReportComponent } from "./virtualsip-report/virtualsip-report.component";
import { CallbackReportComponent } from "./callback-report/callback-report.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RenewalHistoryComponent } from "./usermanagement/renewal-history/renewal-history.component";
import { ProductDetailsComponent } from "./usermanagement/product-details/product-details.component";
import { VoicemailComponent } from './voicemail/voicemail-old/voicemail.component';
import { VoicemailTemplateComponent } from './voicemail/voicemail-template/voicemail-template.component';
import { TemplateListComponent } from './voicemail/template-list/template-list.component';
import { CallTransferCreateTemplateComponent } from './call-transfer/call-transfer-create-template/call-transfer-create-template.component';
import { CallTransferTemplatesListComponent } from './call-transfer/call-transfer-templates-list/call-transfer-templates-list.component';
import { AudioReportComponent } from "./audio-report/audio-report.component";
import { NewTableContentComponent } from "./new-table-content/new-table-content.component";
import { SuperadminAutocallComponent } from "./superadmin-autocall/superadmin-autocall.component";
import { FollowUpReportComponent } from "./Components/follow-up-report/follow-up-report.component";
import { ZohoIntegrationComponent } from "./zoho-integration/zoho-integration.component";
import { LeadsquaredIntegrationComponent } from "./leadsquared-integration/leadsquared-integration.component";
import { KylasIntegrationComponent } from "./kylas-integration/kylas-integration.component";
import { CallerRegistrationComponent } from "./inbound-route/caller-registration/caller-registration.component";
import { IntegrationConfigComponent } from "./integrationconfig/integrationconfig.component";
import { WhatsappConfigurationComponent } from "./whatsapp/whatsapp-configuration/whatsapp-configuration.component";
import { WhatsappTemplatesComponent } from "./whatsapp/whatsapp-templates/whatsapp-templates.component";
import { WhatsappPlaceholdersComponent } from "./whatsapp/whatsapp-placeholders/whatsapp-placeholders.component";
import { WhatsappAccountsComponent } from "./whatsapp/whatsapp-accounts/whatsapp-accounts.component";
import { PushnotificationHistoryComponent } from "./pushnotification-history/pushnotification-history.component";
import { AddPushnotificationComponent } from "./add-pushnotification/add-pushnotification.component";
import { WhatsappReportComponent } from "./whatsapp/whatsapp-report/whatsapp-report.component";
import { WhatsappSettingsComponent } from "./whatsapp/whatsapp-settings/whatsapp-settings.component";
import { SendatatoexternalapilogReportComponent } from "./sendatatoexternalapilog-report/sendatatoexternalapilog-report.component";
import { AddMerittoagentsComponent } from "./add-merittoagents/add-merittoagents.component";
import { FollowUpComponent } from "./Components/follow-up/follow-up.component";
import { BreakTimeReportComponent } from "./break-time-report/break-time-report.component";


const routes: Routes = [
  { path: "", component: AppComponent },
  { path: "impersonate", component: ImpersonateComponent },
  { path: "login", component: LoginComponent },
  { path: "home2", component: DashboardComponent },
  { path: "voicebroadcast/:id", component: VoicebroadcastComponent },
  { path: "voicebroadcastlist", component: VoicebroadcastListComponent },


  { path: "voice-mail", component: VoicemailComponent },
  {
    path: "queue",
    component: QueuemanagemetComponent,
    children: [
      { path: "queuelist", component: QueuelistComponent },
      { path: "addqueue", component: AddqueueComponent },
      { path: "queueDetails/:id", component: AddqueueComponent },
    ],
  },

  {
    path: "extension",
    component: ExtensionComponent,
    children: [
      { path: "addextension/:id", component: AddextensionComponent },
      { path: "extensionlist", component: ExtensionLIstComponent },
    ],
  },

  {
    path: "autoCall",
    component: AutoCallingManagementComponent,
    children: [
      { path: "addAutoCall/:id", component: AddAutoCallComponent },
      { path: "autoCallList", component: ListAutoCallComponent },
    ],
  },

  {
    path: "virtualSip",
    component: VirtualSIpComponent,
    children: [
      { path: "addvirtualSip/:id", component: AddVIrtualSipComponent },
    ],
  },

  { path: "virtualSip/virtualSipList", component: ListVIrtualsipComponent },

  { path: "users", redirectTo: "/users/user", pathMatch: "full" },
  {
    path: "users",
    component: UsersComponent,
    children: [
      { path: "user", component: UserprofileListComponent },
      { path: "usergroup", component: UserGroupListComponent },
    ],
  },

  // {path:'queue',redirectTo:'/queue',pathMatch:'full'},
  {
    path: "queue",
    component: QueuemanagemetComponent,
    children: [
      { path: "queuelist", component: QueuelistComponent },
      { path: "addqueue", component: AddqueueComponent },
      { path: "queueDetails/:id", component: AddqueueComponent },
    ],
  },
  // {path:"user",component:UsermanagementComponentZZ, children:[
  // ]},
  { path: "user", component: UserprofileListComponent },

  { path: "userdetail/:id", component: UserProfileDetailComponent },

  { path: "addAgents", component: AgentUsersComponent },

  { path: "renewalHistory", component: RenewalHistoryComponent },

  { path: "product_details", component: ProductDetailsComponent },

  //queue data edit
  // {path:'ivr',redirectTo:'/ivr',pathMatch:'full'},
  {
    path: "ivr",
    component: IvrComponent,
    children: [
      { path: "ivrList", component: IvrListComponent },
      { path: "ivrDetail/:id", component: IvrDetailComponent },
    ],
  },

  // {path:'credit-management',redirectTo:'/credit-management',pathMatch:'full'},
  {
    path: "credit-management",
    component: CreditManagementComponent,
    children: [
      { path: "add-credit", component: AddCreditComponent },
      { path: "credit-list", component: CreditListComponent },
      { path: "add-channelUsage", component: AddChannelusageComponent },
      { path: "channelUsageHistory", component: ChannelUsageHistoryComponent },
      { path: "timeDuration", component: TimeDurationComponent },
      { path: "credit-history", component: CreditHistoryComponent },
      {
        path: "add-incomingCredit",
        component: IncomingCreditManagementComponent,
      },
      { path: "credit-plan-add/:id", component: CreditPlanComponent },
      {
        path: "add-credit-alert/:id/:alertion/:did",
        component: AddCreditAlertComponent,
      },
      { path: "list-credit-alert", component: ListCreditAlertComponent },
      { path: "report-credit-alert", component: ReportCreditAlertComponent },
      { path: "credit-alert-report", component: CreditAlertReportComponent },
      { path: "add-sms-credit/:id", component: AddSmsCreditComponent },
      { path: "sms-credit-list", component: SmsCreditListComponent },
      { path: "sms-credit-history", component: SmsCreditHistoryComponent },
      { path: "add-user-credit/:id", component: AddUserCreditsComponent },
      { path: "user-credit-list", component: UserCreditListComponent },
      { path: "user-credit-history", component: UserCreditHistoryComponent },
    ],
  },

  { path: "route", redirectTo: "/route", pathMatch: "full" },
  {
    path: "route",
    component: InboundRouteComponent,
    children: [
      { path: "timecondition", component: TimeconditionComponent },
      { path: "timecondition/:id", component: AddtimeconditionComponent },
      { path: "matchpattern", component: MatchPatternListComponent },
      { path: "matchpattern/:id", component: MatchPatternDetailComponent },
      { path: "inboundroute", component: InboundRouteListComponent },
      { path: "inboundroute/:id", component: InboundRouteDetailComponent },
      // This is the report table for reabale table
      { path: "newtable", component: NewTableContentComponent },
      { path: "caller-registration", component: CallerRegistrationComponent },
    ],
  },

  // {path:'ivr',redirectTo:'/ivr/ivrList',pathMatch:'full'},

  { path: "recordsDetails", component: VoiceRecordComponent, children: [] },
  // {path:"report",component:InboundReportComponent, children:[]},
  { path: "userperm", component: UserPermissionComponent },
  { path: "usergroup", component: UserGroupListComponent, children: [] },
  { path: "usergroup/:id", component: UserGroupDetailComponent },
  { path: "usergroupedit/:id", component: UserGroupEditComponent },

  // {path:'dialer',redirectTo:'/dialer',pathMatch:'full'},
  {
    path: "dialer",
    component: DialerComponent,
    children: [
      { path: "dialerList", component: DialerListComponent },
      { path: "addDialer/:id", component: AddDialerComponent },
      { path: "addDialerproject", component: AddDialerProjectComponent },
      { path: "dialerexcel", component: AddDialerExcelComponent },
      { path: "allUploadExcel", component: AllUploadExcelComponent },
    ],
  },

  { path: "projectDetails/:id", component: AddDialerProjectComponent },

  {
    path: "sms",
    component: SmsComponent,
    children: [
      { path: "sms-list", component: SmsListComponent },
      { path: "add-sms", component: AddSmsComponent },
      { path: "view-report", component: ViewReportComponent },
      { path: "templates/:id", component: TemplatesComponent },
      { path: "ivr-templates/:id", component: IvrTemplatesComponent },
      { path: "sms-count", component: SmsCountComponent },
    ],
  },

  {
    path: "addressbook",
    component: AddressbookComponent,
    children: [
      { path: "list", component: CustomercontactComponent },
      { path: "calllog", component: CustomerlogComponent },
    ],
  },

  { path: "demoAutocall", component: SuperadminAutocallComponent },

  { path: "DidmgmtComponent", component: DidmgmtComponent },

  { path: "addSmsDetails/:id", component: AddSmsComponent },

  { path: "edit-did/:id", component: DidmgmtComponent },

  // {path:'reports',redirectTo:'/reports/report',pathMatch:'full'},
  {
    path: "reports",
    component: ReportsComponent,
    children: [
      { path: "report", component: InboundReportComponent },
      { path: "dialer_report", component: DialerReportComponent },
      // { path: "missedCallReport/:missed_call_report", component: MissedCallReportComponent },
      { path: "missedCallReport", component: MissedCallReportComponent },
      { path: "VBReport", component: VbReportComponent },
      { path: "agentReport", component: AgentReportComponent },
      { path: "CallerReport", component: CallerReportComponent },
      { path: "dailpadReport", component: DailpadreportComponent },
      { path: "autocallReport", component: AutocallReportComponent },
      { path: "livecallreport", component: LivecallreportComponent },
      { path: "elasticReport", component: ElasticReportComponent },
      { path: "callCountReport", component: CallCountReportComponent },
      { path: "VirtualsipReport", component: VirtualsipReportComponent },
      { path: "CallbackReport", component: CallbackReportComponent },
      { path: "audioReport", component: AudioReportComponent },
      { path: "sendatatoexternalapilog-report", component: SendatatoexternalapilogReportComponent },
      { path: 'agent-break-report', component: BreakTimeReportComponent }
    ],
  },
  { path: "missedCallReport/:missed_call_report", component: MissedCallReportComponent },
  {
    path: "inboundFeedback",
    redirectTo: "/inboundFeedback",
    pathMatch: "full",
  },
  {
    path: "inboundFeedback",
    component: InboundFeedbackComponent,
    children: [
      { path: "feedBackReport", component: FeedBackReportComponent },
      { path: "listUsers", component: ListUsersComponent },
      { path: "addUsers", component: AddNumbersLogComponent },
      { path: "addQuestions", component: AddQuestionsComponent },
      { path: "questionList", component: QuestionListComponent },
      { path: "inboundUserDetail", component: InboundUserDetailComponent },
    ],
  },

  { path: "virtualSipDetails/:id", component: AddVIrtualSipComponent },

  { path: "", component: AppComponent },
  { path: "impersonate", component: ImpersonateComponent },
  { path: "login", component: LoginComponent },
  { path: "home2", component: DashboardComponent },
  { path: "voicebroadcast/:id", component: VoicebroadcastComponent },
  { path: "voicebroadcastlist", component: VoicebroadcastListComponent },
  { path: "forgotpassword", component: ForgotPasswordComponent },
  { path: "sendemail", component: SendemailComponent },
  {
    path: "reset-password/:uid/:token/:timestamp",component: ResetPasswordComponent,},

  { path: "voice-mail", component: VoicemailComponent },
  {
    path: "queue",
    component: QueuemanagemetComponent,
    children: [
      { path: "queuelist", component: QueuelistComponent },
      { path: "addqueue", component: AddqueueComponent },
      { path: "queueDetails/:id", component: AddqueueComponent },
    ],
  },

  {
    path: "extension",
    component: ExtensionComponent,
    children: [
      { path: "addextension/:id", component: AddextensionComponent },
      { path: "extensionlist", component: ExtensionLIstComponent },
    ],
  },

  {
    path: "autoCall",
    component: AutoCallingManagementComponent,
    children: [
      { path: "addAutoCall/:id", component: AddAutoCallComponent },
      { path: "autoCallList", component: ListAutoCallComponent },
    ],
  },

  {
    path: "virtualSip",
    component: VirtualSIpComponent,
    children: [
      { path: "addvirtualSip/:id", component: AddVIrtualSipComponent },
    ],
  },

  { path: "virtualSip/virtualSipList", component: ListVIrtualsipComponent },

  { path: "users", redirectTo: "/users/user", pathMatch: "full" },
  {
    path: "users",
    component: UsersComponent,
    children: [
      { path: "user", component: UserprofileListComponent },
      { path: "usergroup", component: UserGroupListComponent },
    ],
  },

  // {path:'queue',redirectTo:'/queue',pathMatch:'full'},
  {
    path: "queue",
    component: QueuemanagemetComponent,
    children: [
      { path: "queuelist", component: QueuelistComponent },
      { path: "addqueue", component: AddqueueComponent },
      { path: "queueDetails/:id", component: AddqueueComponent },
    ],
  },
  // {path:"user",component:UsermanagementComponentZZ, children:[
  // ]},
  { path: "user", component: UserprofileListComponent },

  { path: "userdetail/:id", component: UserProfileDetailComponent },

  { path: "addAgents", component: AgentUsersComponent },

  //queue data edit
  // {path:'ivr',redirectTo:'/ivr',pathMatch:'full'},
  {
    path: "ivr",
    component: IvrComponent,
    children: [
      { path: "ivrList", component: IvrListComponent },
      { path: "ivrDetail/:id", component: IvrDetailComponent },
    ],
  },

  // {path:'credit-management',redirectTo:'/credit-management',pathMatch:'full'},
  {
    path: "credit-management",
    component: CreditManagementComponent,
    children: [
      { path: "add-credit", component: AddCreditComponent },
      { path: "credit-list", component: CreditListComponent },
      { path: "add-channelUsage", component: AddChannelusageComponent },
      { path: "channelUsageHistory", component: ChannelUsageHistoryComponent },
      { path: "timeDuration", component: TimeDurationComponent },
      { path: "credit-history", component: CreditHistoryComponent },
      {
        path: "add-incomingCredit",
        component: IncomingCreditManagementComponent,
      },
      { path: "credit-plan-add/:id", component: CreditPlanComponent },
      {
        path: "add-credit-alert/:id/:alertion/:did",
        component: AddCreditAlertComponent,
      },
      { path: "list-credit-alert", component: ListCreditAlertComponent },
      { path: "report-credit-alert", component: ReportCreditAlertComponent },
      { path: "credit-alert-report", component: CreditAlertReportComponent },
      { path: "add-sms-credit/:id", component: AddSmsCreditComponent },
      { path: "sms-credit-list", component: SmsCreditListComponent },
      { path: "sms-credit-history", component: SmsCreditHistoryComponent },
      { path: "add-user-credit/:id", component: AddUserCreditsComponent },
      { path: "user-credit-list", component: UserCreditListComponent },
      { path: "user-credit-history", component: UserCreditHistoryComponent },
    ],
  },

  { path: "route", redirectTo: "/route", pathMatch: "full" },
  {
    path: "route",
    component: InboundRouteComponent,
    children: [
      { path: "timecondition", component: TimeconditionComponent },
      { path: "timecondition/:id", component: AddtimeconditionComponent },
      { path: "matchpattern", component: MatchPatternListComponent },
      { path: "matchpattern/:id", component: MatchPatternDetailComponent },
      { path: "inboundroute", component: InboundRouteListComponent },
      { path: "inboundroute/:id", component: InboundRouteDetailComponent },
    ],
  },

  // {path:'ivr',redirectTo:'/ivr/ivrList',pathMatch:'full'},

  { path: "recordsDetails", component: VoiceRecordComponent, children: [] },
  // {path:"report",component:InboundReportComponent, children:[]},
  { path: "userperm", component: UserPermissionComponent },
  { path: "usergroup", component: UserGroupListComponent, children: [] },
  { path: "usergroup/:id", component: UserGroupDetailComponent },
  { path: "usergroupedit/:id", component: UserGroupEditComponent },

  // {path:'dialer',redirectTo:'/dialer',pathMatch:'full'},
  {
    path: "dialer",
    component: DialerComponent,
    children: [
      { path: "dialerList", component: DialerListComponent },
      { path: "addDialer/:id", component: AddDialerComponent },
      { path: "addDialerproject", component: AddDialerProjectComponent },
      { path: "dialerexcel", component: AddDialerExcelComponent },
      { path: "allUploadExcel", component: AllUploadExcelComponent },
    ],
  },

  { path: "projectDetails/:id", component: AddDialerProjectComponent },

  {
    path: "sms",
    component: SmsComponent,
    children: [
      { path: "sms-list", component: SmsListComponent },
      { path: "add-sms", component: AddSmsComponent },
      { path: "view-report", component: ViewReportComponent },
      { path: "templates/:id", component: TemplatesComponent },
      { path: "sms-count", component: SmsCountComponent },
    ],
  },

  {
    path: "addressbook",
    component: AddressbookComponent,
    children: [
      { path: "list", component: CustomercontactComponent },
      { path: "calllog", component: CustomerlogComponent },
    ],
  },

  { path: "addressbook/:customerId/:followUpId", component: FollowUpComponent },

  { path: "DidmgmtComponent", component: DidmgmtComponent },

  { path: "addSmsDetails/:id", component: AddSmsComponent },

  { path: 'voicemail-template', component: VoicemailTemplateComponent },
  { path: 'template-list', component: TemplateListComponent },
  { path: 'template-edit/:id', component: VoicemailTemplateComponent },

  { path: 'create-call-transfer', component: CallTransferCreateTemplateComponent },
  { path: 'call-transfer-list', component: CallTransferTemplatesListComponent },
  { path: 'call-transfer-edit/:id', component: CallTransferCreateTemplateComponent },
  { path: 'zoho-integration', component: ZohoIntegrationComponent },
  { path: 'follow-up-report', component: FollowUpReportComponent },
  { path: 'leadsquared-integration', component: LeadsquaredIntegrationComponent },
  { path: 'kylas-integration', component: KylasIntegrationComponent },
  { path: 'integration-config', component: IntegrationConfigComponent },

  { path: 'whatsapp-configuration', component: WhatsappConfigurationComponent },
  { path: 'whatsapp-templates', component: WhatsappTemplatesComponent },
  { path: 'whatsapp-placeholders', component: WhatsappPlaceholdersComponent },
  { path: 'whatsapp-accounts', component: WhatsappAccountsComponent },
  { path: 'whatsapp-report', component: WhatsappReportComponent },
  { path: 'whatsapp-settings', component: WhatsappSettingsComponent },
  { path: 'pushnotification-history', component: PushnotificationHistoryComponent },
  { path: 'add-pushnotification', component: AddPushnotificationComponent },
  { path: 'add-merittoagents', component: AddMerittoagentsComponent}


]; KylasIntegrationComponent

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
