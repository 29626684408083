import { LoginService } from "src/app/login.service";
import { logging } from "protractor";
import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";

@Component({
  selector: "[app-forgot-password]",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  email: string = "";
  username: string = "";
  token: string = "";
  uid: string = "";

  showResetPassword: boolean = false;

  Url: any = window.location.href.split("/");
  host: any = this.Url[0] + "//" + this.Url[2];

  constructor(
    private loginservice: LoginService) {}

  ngOnInit() {
    console.log("+++++++++++++++++", this.Url, "+++++++++++++++");
  }

  forgotPassword() {
    console.log("*********************", this.host, "-------------------");
    this.loginservice
      .doForgotPassword(this.email, this.host, this.username)
      .subscribe((res: any) => {
        this.uid = res["uid"];
        this.token = res["token"];
        console.log("5555555555555555555",res.status)
        if (res.status == 1) {
          Swal.fire({
            title: "Password Reset Link Sent! ",
            text: "Check your email to reset your password.",
          });
        }
      });
  }
}
