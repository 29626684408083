import { Component, OnInit } from "@angular/core";
import { ReportService } from "./report.service";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import * as moment from "moment";
import { API_URL, VOICE_URL } from "src/global";
import * as jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { ExcelService } from "./excel.service";
import { DatePipe } from "@angular/common";
import { LoginService } from "src/app/login.service";
import { NotificationService } from "src/app/notification/notification.service";
import Swal from "sweetalert2";
import "jspdf-autotable";
import { HttpEventType, HttpEvent } from "@angular/common/http";

import * as saveAs from "file-saver";
import { ReportSettingsService } from "../report-settings/report-settings.service";

@Component({
  selector: "app-inbound-report",
  templateUrl: "./inbound-report.component.html",
  styleUrls: ["./inbound-report.component.css"],
  providers: [DatePipe],
})
export class InboundReportComponent implements OnInit {
  private _value: number = 0;
  public cadmin_q_QueueTransfer = false;
  public cadmin_w_callTransfer = false;
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    if (!isNaN(value) && value <= 100) {
      this._value = value;
    }
  }

  countryIcons = {
    "91": "fa-flag-in",
    "1": "fa-flag-us",
  };

  InbountReportData: any = new Array();
  wordarray: any = new Array();
  current_page: number = 1;
  total_page: any;
  last: number;
  fromdate: string = "";
  todate: string = "";
  goPage: number;
  searchText: string = "";
  is_pbx: boolean = false;
  userData: any;
  callStatus: string = "";
  myDate = new Date();
  today = new Date(this.myDate.setDate(this.myDate.getDate()));
  today_informat = this.datePipe.transform(this.today, "yyyy-MM-dd");
  ExcelPdfReportData: any = new Array();
  api = API_URL;
  voice_url = VOICE_URL;
  permissions: any;
  permis = false;
  isShownprogressbar = false;
  progress: number = 0;
  public id: any = 0;
  userType: any;
  mobile_number: any;
  isExpanded: false

  // Settingsforcomment
  commentopen: boolean = false;
  comment: any;
  call_id: any;
  callRecors_id: any;
  tagList: any = new Array();
  tag_filter: string = "";
  showTagDiv: boolean = false;

  constructor(
    private reportservice: ReportService,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private loginservice: LoginService,
    private notifyService: NotificationService,
    private reportsettingservice: ReportSettingsService
  ) {}

  ngOnInit() {
    if (this.api == "//backend.pbx.bonvoice.com/") {
      this.is_pbx = true;
    }

    this.userData = this.loginservice.getUserdata(true);
    this.permissions = this.userData.permissions;
    this.userType = localStorage.getItem("userType");

    console.log("user type in inbound report    :  ", this.userType);
    for (let element of this.permissions) {
      if (element.codename == "cadmin_o_inboundreport") {
        this.permis = true;
      }

      if (element.codename == "cadmin_q_QueueTransfer") {
        this.cadmin_q_QueueTransfer = true;
      }
      if (element.codename == "cadmin_w_callTransfer") {
        this.cadmin_w_callTransfer = true;
      }
    }
    this.getCallTags();
    this.FetchInboundReportData(1);
  }

  getRecordId(data: any): string {
    // Check if callRecVoiceFile is empty, null, or undefined
    if (!data.callRecordVF || data.callRecordVF.trim() === "") {
      // If empty, null, or undefined, use data.id
      return data.id;
    } else {
      return data.callRecordVF;
    }
  }

  FetchInboundReportData(pageNo) {
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd");

    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        pageNo,
        "",
        this.userType,
        this.tag_filter
      ).subscribe((res: any) => {
        console.log("<<<<<<<<<<<<<<<<<<<<<<<<",res["results"],">>>>>>>>>>>>>>>>>>>>>>>>>");
        this.InbountReportData = res["results"];
        this.total_page = res["total_pages"];
        this.current_page = res["current_page"];
        this.last = res["total_pages"];
        // alert(JSON.stringify(this.InbountReportData))
      });
  }

  searchBox() {
    this.FetchInboundReportData(1);
  }

  getcallrecord(vffile, date, call_id, file_type = "") {
    this.reportservice
      .getcallrecord(vffile, date, call_id, file_type)
      .subscribe((res: any) => {
        let voice_url =
          this.api +
          "uploads/downloadedWaveFile/" +
          vffile.replace(".gsm", ".wav");
        var html_con =
          '<audio id="audio1" preload controls style="width: 270px; height: 50px; max-width: none; margin: 10px;">' +
          "  <source src=" +
          voice_url +
          " </audio>";
        document.getElementById("audio_" + vffile).innerHTML = html_con;

        this.notifyService.sendNotification(
          "Downloaded",
          "Download Voice successfully",
          "is-success"
        );
      });
  }

  exportAsXLSX(): void {
    var text;
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd");

    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        this.current_page,
        "yes",
        this.userType,
        this.tag_filter
      )
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["data"];
        for (let element of this.ExcelPdfReportData) {
          if (element["destinationType"] == "6") {
            var excel_destination_name = "AUDIO";
          } else {
            excel_destination_name = element["queueName"];
          }

          var objz = {
            DID: element["DID"],
            Customer: element["callerid"],
            countrycode: element["country_code"],
            "Customer Name": element["customer"]["name"] || "",
            Date: element["legAstartTime"],
            "Agent Number": element["answeredAgent"],
            "Agent Name": element["agentName"],
            "Destination Name": excel_destination_name,
            DTMF: element["DTMS"],
            Duration: element["duration"],
            "Connected Duration": element["connectedDuration"],
            Comment: element["comment"],
            Tags: element["tag"],
            Status: element["callStatus"],
          };

          this.wordarray.push(objz);
        }
        if ((this.fromdate, this.todate)) {
          text = "Inbound Report from: " + from + " To: " + to + "";
        } else {
          text = "Inbound Report of " + this.today_informat;
        }
        this.excelService.exportAsExcelFile(this.wordarray, text);
        this.wordarray = [];
      });
  }

  downloads() {
    var countSlNo = 1;
    var newarray = [];
    var head = [
      [
        "SlNo",
        "DID",
        "Customer",
        "Country code",
        "Customer Name",
        "Date",
        "Agent",
        "Agent Name",
        "Department Name",
        "Call Duration",
        "DTMF",
        "Connected Duration",
        "comment",
        "Tags",
        "Status",
      ],
    ];
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd");
    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        this.current_page,
        "yes",
        this.userType,
        this.tag_filter
      )
      .subscribe((res: any) => {
        this.ExcelPdfReportData = res["data"];
        for (let element of this.ExcelPdfReportData) {
          newarray.push([
            countSlNo,
            element["DID"],
            element["callerid"],
            element["country_code"],
            element["customer"]["name"] || "",
            element["legAstartTime"],
            element["answeredAgent"],
            element["agentName"],
            element["queueName"],
            element["duration"],
            element["DTMS"],
            element["connectedDuration"],
            element["comment"],
            element["tag"],
            element["callStatus"],
          ]);
          countSlNo = countSlNo + 1;
        }
        var pdf = new jsPDF();
        pdf.text("Inbound Report", 11, 8);
        pdf.setFontSize(3);
        pdf.setTextColor(100);
        (pdf as any).autoTable({
          head: head,
          body: newarray,
          theme: "grid",
          styles: { fontSize: 4 },
          tableWidth: "auto",
          columnStyles: { 0: { halign: "center", fillColor: [0, 255, 0] } }, // Cells in first column centered and green
          margin: { top: 10 },
        });
        pdf.output("dataurlnewwindow");
        pdf.save("Inboubdreport" + from + "_to_" + to + ".pdf");
      });
  }

  fetchNumber(i) {
    if (i == "prev") {
      if (this.current_page > 1) {
        this.FetchInboundReportData(this.current_page - 1);
      }
    } else if (i == "next") {
      if (this.current_page < this.last) {
        this.FetchInboundReportData(this.current_page + 1);
      }
    } else if (i == "first") {
      this.FetchInboundReportData(1);
    } else if (i == "last") {
      this.FetchInboundReportData(this.last);
    } else {
      if (parseInt(i) === parseInt(i, 10) && i > 0 && i <= this.last) {
        this.FetchInboundReportData(i);
      } else {
        Swal.fire("Oops..!", "Enter Valid Number", "warning");
      }
    }
  }

  DownloadallVoice() {
    var from = this.datePipe.transform(this.fromdate, "yyyy-MM-dd");
    var to = this.datePipe.transform(this.todate, "yyyy-MM-dd");
    this.reportservice
      .getInboundReport(
        this.userData.id,
        from,
        to,
        this.searchText,
        this.callStatus,
        this.current_page,
        "voice_download_yes",
        this.userType,
        this.tag_filter
      )
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.isShownprogressbar = true;
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.DownloadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            var a = document.createElement("a");
            var blob = new Blob([event.body], { type: "application/zip" });
            a.href = URL.createObjectURL(blob);
            if (from && to) {
              a.download = from + "_" + to + ".zip";
            } else {
              a.download = "INBOUND_" + this.today_informat + ".zip";
            }
            a.click();
            setTimeout(() => {
              this.progress = 0;
              this.isShownprogressbar = false;
            }, 1500);
        }
      });
  }

  onDownload(): void {
    this.id = 1;
    let filename = null;
    this.reportservice.download().subscribe((event) => {

      if (event["loaded"] && event["total"]) {
        this.value = Math.round((event["loaded"] / event["total"]) * 100);

        // if (this.value==100){
        //   // this.id=0;
        // }
      }

      if (event["body"]) {
        saveAs(event["body"], filename);
      }
    });
    // this.id=0;
  }

  CloseComment() {
    this.commentopen = false;
    this.call_id = "";
    this.id = "";
    this.comment = "";
    this.callRecors_id = "";
  }

  audioFileErrors: boolean[] = [];
  downloadEnabled: boolean[] = [];

  handleAudioError(audioFileName: string) {
    this.audioFileErrors[audioFileName] = true;
    this.downloadEnabled[audioFileName] = true;
  }

  disableVoiceDownload(audioFileName: string) {
    this.downloadEnabled[audioFileName] = false;
  }

  addCallRecordTag(call_record_id: any, index: any) {
    this.reportsettingservice
      .getReportTagChild(call_record_id)
      .subscribe((res: any) => {
        const updatedTags = res.tag.split(",").map((tag) => tag.trim());
        this.InbountReportData[index].tag = updatedTags.join(", ");
      });
  }

  deleteCallRecordTag(call_record_id: any, tag: any, index: any) {
    const body = {
      tag: tag,
    };

    this.reportsettingservice
      .deleteReportTagChild(call_record_id, body)
      .subscribe((res: any) => {
        this.InbountReportData[index].tag = res.tags.tag;
      });
  }

  updateReportPage() {
    this.FetchInboundReportData(this.current_page);
  }

  getCallTags() {
    let user_id: any;
    if (
      this.userData.usertype == "agentUser" ||
      this.userData.usertype == "dialerUser"
    ) {
      user_id = this.userData.admin;
    } else {
      user_id = this.userData.id;
    }

    this.reportsettingservice
      .getReportTagMain(user_id)
      .subscribe((res: any) => {
        this.tagList = res.report_tags;
      });
  }
}
